import {
    isMobile
} from "react-device-detect";
//const isMobile = true;

// Configuration for API
const API_URL = "https://service.circoloilvapattinaggio.com/";
const SITE_ROOT ="/"

class DEFINE {
  constructor(t) {
    this.YesNoAllTag = {
        no: 'p-tag-danger p-tag-laika',
        yes: 'p-tag-success p-tag-laika',
    };

    this.YesNoAll = [
      {nome: t('button.no'), valore: 0},
      {nome: t('button.yes'), valore: 1}
    ];

    this.ruoliTag = {
        atleta: 'p-tag-primary p-tag-laika',
        allenatore: 'p-tag-info p-tag-laika',
        dirigente: 'p-tag-warning p-tag-laika'
    };

    this.ruoli = [
        {nome: t('roles.atleta'), valore: 'atleta', tag: this.ruoliTag.atleta},
        {nome: t('roles.allenatore'), valore: 'allenatore', tag: this.ruoliTag.allenatore},
        {nome: t('roles.dirigente'), valore: 'dirigente', tag: this.ruoliTag.dirigente}
    ];
  }
}

export {
    API_URL,
    SITE_ROOT,
    DEFINE,
    isMobile
};
