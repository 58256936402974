import React, { Component } from 'react';
import {DEFINE, isMobile} from "../Config/config";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LezioniService } from '../Services/LezioniService';
import { CorsiService } from '../Services/CorsiService';
import { AnagraficheService } from '../Services/AnagraficheService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { date, serverFormatDate, serverFormatDateTime, clientFormatDateTime, calendarLocale } from '../Utils/DateHelper'
import { fixInt } from '../Utils/ObjectHelper';

class Lezioni extends Component {

  emptyLezione = {
      idlezione: "",
      corso_nome: "",
      data_lezione: "",
      minuti: "",
      data_recupero: "",
      da_recuperare: 0,
      note: "",
      anagrafiche: []
  };

  emptyLezioneHasAnagrafica = {
      id: "",
      lezione_idlezione: "",
      lezione_atleta: 1,
      lezione_prova: 0,
      lezione_nota: "",
      cf: "",
      cognome: "",
      nome: "",
      atleta: 0,
      allenatore: 0,
      dirigente: 0
  };

  constructor(props) {
      super(props);

      this.state = {
          anagraficheCorso: [],
          allenatori: null,
          corsi: null,
          lezioni: null,
          lezioneDialog: false,
          deleteLezioneDialog: false,
          deleteLezioniDialog: false,
          lezione: this.emptyLezione,
          lezioneOriginalAnagrafiche: [],
          selectedLezioni: null,
          selectedLezioneAnagrafiche : null,
          selectedDataLezione: "",
          selectedDataRecupero: "",
          selectedDaRecuperare: "",
          selectedCorsi: null,
          selectedAllenatori: [],
          selectedAnagraficheCorso: [],
          selectedAnagrafiche: [],
          filteredAnagrafiche: [],
          selectedRuoli: [],
          selectAtleta: null,
          selectProva: null,
          submitted: false,
          isNew: false,
          showColumns: props.showColumns ? props.showColumns : ["corso_nome","data_lezione","data_recupero","da_recuperare","note"],
          showToolbar: typeof props.showToolbar === 'undefined' ? true : props.showToolbar,
          loading: false,
          first: 0,
          rows: 20,
          rowsPerPageOptions: [20, 50, 100, 200],
          totalRecords: 0,
          multiSortMeta: [],
          filters: null,
          globalFilter: null
      };

      this.columns = [
          {nome: props.t('label.corso_nome'), valore: 'corso_nome'},
          {nome: props.t('label.data_lezione'), valore: 'data_lezione'},
          {nome: props.t('label.data_recupero'), valore: 'data_recupero'},
          {nome: props.t('label.da_recuperare'), valore: 'da_recuperare'},
          {nome: props.t('label.note'), valore: 'note'}
      ];

      const define = new DEFINE(props.t);
      this.YesNoAllTag = define.YesNoAllTag;
      this.YesNoAll = define.YesNoAll;
      this.ruoliTag = define.ruoliTag;
      this.ruoli = define.ruoli;

      this.calendarLocale = calendarLocale(props.t('locale'));

      this.lezioniService = new LezioniService();
      this.corsiService = new CorsiService();
      this.anagraficheService = new AnagraficheService();
      this.corsoNomeBodyTemplate = this.corsoNomeBodyTemplate.bind(this);
      this.dataLezioneBodyTemplate = this.dataLezioneBodyTemplate.bind(this);
      this.dataRecuperoBodyTemplate = this.dataRecuperoBodyTemplate.bind(this);
      this.daRecuperareBodyTemplate = this.daRecuperareBodyTemplate.bind(this);
      this.lezioneAtletaBodyTemplate = this.lezioneAtletaBodyTemplate.bind(this);
      this.lezioneProvaBodyTemplate = this.lezioneProvaBodyTemplate.bind(this);
      this.noteBodyTemplate = this.noteBodyTemplate.bind(this);
      this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

      this.onSelected = this.onSelected.bind(this);
      this.onFilterChange = this.onFilterChange.bind(this);
      this.onDataFilterChange = this.onDataFilterChange.bind(this);
      this.onDataTimeFilterChange = this.onDataTimeFilterChange.bind(this);
      this.onRuoloFilterChange = this.onRuoloFilterChange.bind(this);

      this.openNew = this.openNew.bind(this);
      this.hideDialog = this.hideDialog.bind(this);
      this.saveLezione = this.saveLezione.bind(this);
      this.editLezione = this.editLezione.bind(this);
      this.confirmDeleteLezione = this.confirmDeleteLezione.bind(this);
      this.deleteLezione = this.deleteLezione.bind(this);
      this.exportCSV = this.exportCSV.bind(this);
      this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
      this.deleteSelectedLezioni = this.deleteSelectedLezioni.bind(this);
      this.onInputChange = this.onInputChange.bind(this);
      this.onDateChange = this.onDateChange.bind(this);
      this.onInputNumberChange = this.onInputNumberChange.bind(this);
      this.onSelectChange = this.onSelectChange.bind(this);
      this.onSelectAllenatoriChange = this.onSelectAllenatoriChange.bind(this);

      this.hideDeleteLezioneDialog = this.hideDeleteLezioneDialog.bind(this);
      this.hideDeleteLezioniDialog = this.hideDeleteLezioniDialog.bind(this);

      this.corsiItemTemplate = this.corsiItemTemplate.bind(this);
      this.anagraficheItemTemplate = this.anagraficheItemTemplate.bind(this);

      this.onRefresh = this.onRefresh.bind(this);
      this.onClear = this.onClear.bind(this);
      this.onPage = this.onPage.bind(this);
      this.onFilter = this.onFilter.bind(this);
      this.onSort = this.onSort.bind(this);

      this.originalRows = {};
      this.onRowEditInit = this.onRowEditInit.bind(this);
      this.onRowEditCancel = this.onRowEditCancel.bind(this);
      this.emptyValueValidator = this.emptyValueValidator.bind(this);

      this.ruoliBodyTemplate = this.ruoliBodyTemplate.bind(this);
      this.nomeBodyTemplate = this.nomeBodyTemplate.bind(this);
      this.cognomeBodyTemplate = this.cognomeBodyTemplate.bind(this);

      this.ruoliItemTemplate = this.ruoliItemTemplate.bind(this);
      this.searchAnagrafiche = this.searchAnagrafiche.bind(this);
      this.exportAnagraficheFunction = this.exportAnagraficheFunction.bind(this);
      this.exportFunction = this.exportFunction.bind(this);
  }

  componentDidMount() {
      this.onRefresh();
      this.corsiService.getCorsi().then(result => {
        if(result.isOk){
          this.setState({ corsi: result.data });
        }
      });
      this.anagraficheService.getAllenatori().then(result => {
        if(result.isOk){
          this.setState({ allenatori: result.data });
        }
      });
  }

  componentDidUpdate(prevProps){
      if(prevProps.showColumns!==this.props.showColumns){
        this.setState({showColumns: this.props.showColumns ? this.props.showColumns : ["corso_nome","data_lezione","data_recupero","da_recuperare","note"]});
      }
      if(prevProps.showToolbar!==this.props.showToolbar){
        this.setState({showToolbar: typeof this.props.showToolbar === 'undefined' ? true : this.props.showToolbar});
      }
  }

  onLazyLoading(first,rows,filters,multiSortMeta) {
      if(this.state.loading){
        return;
      }

      this.setState({
          loading: true,
      });

      this.lezioniService.getLezioni({first:first,size:rows,multiSortMeta:multiSortMeta},filters).then(result => {
        if(result.isOk){
          this.setState({
              first: first,
              rows: rows,
              lezioni: result.data,
              totalRecords: result.total,
              loading: false
          });
        } else{
          this.setState({
              loading: false
          });
          this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
        }
      });
  }

  onRefresh() {
      this.onLazyLoading(this.state.first,this.state.rows,this.state.filters,this.state.multiSortMeta);
  }

  onClear() {
      this.setState({
        selectedDataLezione: "",
        selectedDataRecupero: "",
        selectedDaRecuperare: null,
        selectedCorsi: null,
        multiSortMeta: [],
        filters: null
      });
      if(this.dlf) this.dlf.inputElement.value=null;
      if(this.drf) this.drf.inputElement.value=null;
      this.onLazyLoading(0,this.state.rows,null,[]);
  }

  onPage(event) {
      this.onLazyLoading(event.first,event.rows,this.state.filters,event.multiSortMeta);
  }

  onSort(event) {
    this.setState({
        multiSortMeta: event.multiSortMeta
    });
    this.onLazyLoading(this.state.first,this.state.rows,this.state.filters,event.multiSortMeta);
  }

  onFilter(event) {
    this.setState({
        filters: event.filters
    });
    this.onLazyLoading(this.state.first,this.state.rows,event.filters,this.state.multiSortMeta);
  }

  onSelected(name,value){
    switch(name){
      case "data_lezione":
          this.setState({ selectedDataLezione: value });
          break;
      case "data_recupero":
          this.setState({ selectedDataRecupero: value });
          break;
      case "da_recuperare":
          this.setState({ selectedDaRecuperare: value });
          break;
      case "corso_nome":
          this.setState({ selectedCorsi: value });
          break;
      case "searchText":
          this.setState({ globalFilter: value });
          break;
      default:
        break;
    }
  }

  onFilterChange(value,name,matchMode){
    /*
    startsWith : Checks if column value starts with the filter value.
    endsWith : Checks if column value ends with the filter value.
    contains : Checks if column value contains the filter value.
    exact : Checks if string representations of column value and filter value are same.
    lt : Checks if column value is less than the filter value.
    lte : Checks if column value is less than or equals the filter value.
    gt : Checks if column value is greater than the filter value.
    gte : Checks if column value is greater than or equals the filter value.
    equals : Checks if column value equals the filter value.
    in : Checks if column value is in the collection of the filter value.
    custom: ...
     */
    this.dt.filter(value, name, matchMode);
    this.onSelected(name,value);
  }

  onRuoloFilterChange(e) {
      if(e.value.includes('atleta')!==this.state.selectedRuoli.includes('atleta')){
        this.dta.filter(e.value.includes('atleta') ? 1 : null, 'atleta', 'equals');
      }
      if(e.value.includes('allenatore')!==this.state.selectedRuoli.includes('allenatore')){
        this.dta.filter(e.value.includes('allenatore') ? 1 : null, 'allenatore', 'equals');
      }
      if(e.value.includes('dirigente')!==this.state.selectedRuoli.includes('dirigente')){
        this.dta.filter(e.value.includes('dirigente') ? 1 : null, 'dirigente', 'equals');
      }
      this.setState({ selectedRuoli: e.value });
  }

  onDataTimeFilterChange(value, name, matchMode) {
    if(value){
      this.dt.filter(serverFormatDateTime(new Date(value)), name, matchMode);
      this.onSelected(name,new Date(value));
    }
    else{
      this.dt.filter(null, name, matchMode);
      this.onSelected(name,null);
    }
  }

  onDataFilterChange(value, name, matchMode) {
    if(value){
      this.dt.filter(serverFormatDate(new Date(value)), name, matchMode);
      this.onSelected(name,new Date(value));
    }
    else{
      this.dt.filter(null, name, matchMode);
      this.onSelected(name,null);
    }
  }

  openNew() {
      this.setState({
          lezione: this.emptyLezione,
          lezioneOriginalAnagrafiche : [],
          selectedLezioneAnagrafiche: null,
          selectedRuoli: [],
          selectedAllenatori: [],
          selectedAnagraficheCorso: [],
          selectedAnagrafiche: [],
          filteredAnagrafiche: [],
          submitted: false,
          lezioneDialog: true,
          isNew: true
      });
  }

  hideDialog() {
      this.setState({
          submitted: false,
          lezioneDialog: false
      });
  }

  hideDeleteLezioneDialog() {
      this.setState({ deleteLezioneDialog: false });
  }

  hideDeleteLezioniDialog() {
      this.setState({ deleteLezioniDialog: false });
  }

  saveLezione() {
      if(this.state.loading){
        return;
      }

      if (this.state.lezione.corso_nome && this.state.lezione.corso_nome.trim() && this.state.lezione.data_lezione && this.state.lezione.data_lezione.trim() && this.state.lezione.minuti && this.state.lezione.minuti>0) {
          this.setState({
              loading: true,
          });
          let lezione = {...this.state.lezione};
          if (this.state.isNew) {
              this.lezioniService.createLezione(lezione).then(result => {
                if(result.isOk){
                  this.setState({
                      loading: false,
                      lezioneDialog: false,
                      lezione: this.emptyLezione,
                      lezioneOriginalAnagrafiche: []
                  });
                  this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.lezione_created_ok'), life: 5000 });
                  this.onRefresh();
                }
                else{
                  this.setState({
                    loading: false,
                  });
                  this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
                }
              });
          }
          else {
              this.lezioniService.updateLezione(lezione).then(result => {
                if(result.isOk){
                  this.setState({
                      loading: false,
                      lezioneDialog: false,
                      lezione: this.emptyLezione,
                      lezioneOriginalAnagrafiche: []
                  });
                  this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.lezione_updated_ok'), life: 5000 });
                  this.onRefresh();
                }
                else{
                  this.setState({
                    loading: false,
                  });
                  this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
                }
              });
          }
      }

      this.setState({
          submitted: true,
      });

  }

  refreshSelectedAllenatori(anagrafiche){
    let selectedAllenatori = [];
    if(anagrafiche){
      anagrafiche.forEach((item, i) => {
        if(item.lezione_atleta===0 && item.allenatore===1){
          selectedAllenatori.push(item.cf);
        }
      });
    }
    this.setState({
        selectedAllenatori: selectedAllenatori
    });
  }

  refreshSelectedAnagraficheCorso(anagrafiche, anagrafiche_corso){
    let selectedAnagraficheCorso = [];
    if(anagrafiche && anagrafiche_corso){
      anagrafiche.forEach((item, i) => {
        if(anagrafiche_corso.find((el)=> item.cf===el.cf)){
          selectedAnagraficheCorso.push(item.cf);
        }
      });
    }
    this.setState({
        selectedAnagraficheCorso: selectedAnagraficheCorso
    });
  }

  refreshSelectedAnagrafiche(anagrafiche, anagrafiche_corso){
    let selectedAnagrafiche = [];
    if(anagrafiche && anagrafiche_corso){
      anagrafiche.forEach((item, i) => {
        if(!anagrafiche_corso.find((el) => item.cf===el.cf) && !this.state.allenatori.find((el) => item.cf===el.cf && el.atleta===0 && el.dirigente===0)){
          selectedAnagrafiche.push(item);
        }
      });
    }
    else{
      anagrafiche.forEach((item, i) => {
        if(!this.state.allenatori.find((el) => item.cf===el.cf && el.atleta===0 && el.dirigente===0)){
          selectedAnagrafiche.push(item);
        }
      });
    }
    this.setState({
        selectedAnagrafiche: selectedAnagrafiche
    });
  }


  refreshAnagraficheCorso(corso_nome,anagrafiche){
    this.setState({
        loading: true
    });
    this.anagraficheService.getAnagraficheCorso(corso_nome).then(result => {
      if(result.isOk && result.data){
        this.refreshSelectedAnagraficheCorso(anagrafiche, result.data);
        this.refreshSelectedAnagrafiche(anagrafiche, result.data);
        this.setState({
            anagraficheCorso: result.data,
            loading: false
        });
      }
      else{
        this.refreshSelectedAnagrafiche(anagrafiche);
        this.setState({
            loading: false
        });
      }
    });
  }

  editLezione(lezione) {
      if(this.state.loading){
        return;
      }

      this.setState({
          loading: true,
          selectedRuoli: [],
          selectedAllenatori: [],
          selectedAnagraficheCorso: [],
          selectedAnagrafiche: [],
          filteredAnagrafiche: []
      });

      this.lezioniService.getLezione(lezione ? lezione.idlezione :null).then(result => {
        if(result.isOk){
          if(result.data){
            this.refreshSelectedAllenatori(result.data.anagrafiche);
            this.setState({
                lezione: result.data,
                lezioneOriginalAnagrafiche: result.data.anagrafiche,
                selectedLezioneAnagrafiche: null,
                lezioneDialog: true,
                isNew: false
            });
            this.refreshAnagraficheCorso(result.data.corso_nome,result.data.anagrafiche);
          }
          else{
            this.setState({
                loading: false
            });
            this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t('error.not_found'), life: 5000 });
          }
        } else{
          this.setState({
              loading: false
          });
          this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
        }
      });

  }

  confirmDeleteLezione(lezione) {
      this.setState({
          lezione,
          deleteLezioneDialog: true
      });
  }

  deleteLezione() {
      if(this.state.loading){
        return;
      }

      this.setState({
          loading: true,
      });

      this.lezioniService.deleteLezione(this.state.lezione.idlezione).then(result => {
        if(result.isOk){
          let lezioni = this.state.lezioni.filter(val => val.idlezione !== this.state.lezione.idlezione);
          this.setState({
              lezioni,
              loading: false,
              deleteLezioneDialog: false,
              lezione: this.emptyLezione
          });
          this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.lezione_deleted_ok'), life: 5000 });
          this.onRefresh();
        }
        else{
          this.setState({
              loading: false,
              deleteLezioneDialog: false,
          });
          this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
        }
      });
  }

  exportCSV() {
      this.dt.exportCSV();
  }

  confirmDeleteSelected() {
      this.setState({ deleteLezioniDialog: true });
  }

  deleteSelectedLezioni() {
      let lezioni = this.state.lezioni.filter(val => !this.state.selectedLezioni.includes(val));
      this.setState({
          lezioni,
          deleteLezioniDialog: false,
          selectedLezioni: null
      });
      this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.lezioni_deleted_ok'), life: 5000 });
  }

  onInputChange(e, name) {
      let val = (e.target && e.target.value);
      val = val || val===0 ? val : '';
      let lezione = {...this.state.lezione};
      lezione[`${name}`] = val;

      this.setState({ lezione });
  }

  onDateChange(e, name) {
      const val = (e.target && e.target.value) || '';
      let lezione = {...this.state.lezione};
      lezione[`${name}`] = val === undefined || val === null || (typeof val === 'string' && val.trim() === '') ? null : serverFormatDateTime(val);

      this.setState({ lezione });
  }

  onInputNumberChange(e, name) {
      const val = e.value || 0;
      let lezione = {...this.state.lezione};
      lezione[`${name}`] = val;
      if(name==="minuti" && val===0){
        const corso = this.state.corsi.find((corso) => corso.nome===lezione.corso_nome);
        if(corso){
          lezione.minuti = corso.lezione_minuti;
          if(this.inm) this.inm.inputEl.value=corso.lezione_minuti;
        }
      }
      this.setState({ lezione });
  }

  onSelectChange(e, name) {
    const val = e.value || null;
    let lezione = {...this.state.lezione};
    lezione[`${name}`] = val;
    if(name==="corso_nome"){
      this.refreshAnagraficheCorso(lezione.corso_nome,lezione.anagrafiche);
      const corso = this.state.corsi.find((corso) => corso.nome===val);
      if(corso){
        lezione.minuti = corso.lezione_minuti;
        if(this.inm) this.inm.inputEl.value=corso.lezione_minuti;
      }
    }
    this.setState({ lezione });
  }

  onSelectAllenatoriChange(e){
    const allenatori = this.state.allenatori;
    let lezione = Object.assign({}, this.state.lezione);
    let anagrafiche = lezione.anagrafiche.filter((el) => (el.atleta===1 || e.value.includes(el.cf)));
    let added = e.value.filter((el) => !anagrafiche.find((a) => a.cf===el));
    added.forEach((item, i) => {
      let anagrafica = this.state.lezioneOriginalAnagrafiche.find((el) => el.cf===item);
      anagrafica = anagrafica ? anagrafica : allenatori.find((el) => el.cf===item);
      let lezioneHasAnagrafica = Object.assign({}, this.emptyLezioneHasAnagrafica);
      lezioneHasAnagrafica.lezione_idlezione =  this.state.lezione.idlezione;
      lezioneHasAnagrafica.lezione_atleta=0;
      lezioneHasAnagrafica.lezione_atleta=anagrafica.atleta;
      lezioneHasAnagrafica.cf=anagrafica.cf;
      lezioneHasAnagrafica.cognome=anagrafica.cognome;
      lezioneHasAnagrafica.nome=anagrafica.nome;
      lezioneHasAnagrafica.atleta=anagrafica.atleta;
      lezioneHasAnagrafica.allenatore=anagrafica.allenatore;
      lezioneHasAnagrafica.dirigente=anagrafica.dirigente;
      anagrafiche.push(lezioneHasAnagrafica);
    });
    anagrafiche.forEach((item, i) => {
      item.lezione_atleta= e.value.find((el) => item.cf===el) ? 0 : 1;
    });
    lezione.anagrafiche = anagrafiche;
    this.setState({ selectedAllenatori: e.value , lezione});
    this.refreshSelectedAnagrafiche(lezione.anagrafiche, this.state.anagraficheCorso);
  }

  onSelectAnagraficheCorsoChange(e){
    const anagraficheCorso = this.state.anagraficheCorso;
    let lezione = Object.assign({}, this.state.lezione);
    let anagrafiche = lezione.anagrafiche.filter((el) => (!anagraficheCorso.find((el2) => el.cf===el2.cf) || e.value.find((el2) => el2===el.cf)) );
    let added = e.value.filter((el) => !anagrafiche.find((a) => a.cf===el));
    added.forEach((item, i) => {
      let anagrafica = this.state.lezioneOriginalAnagrafiche.find((el) => el.cf===item);
      anagrafica = anagrafica ? anagrafica : anagraficheCorso.find((el) => el.cf===item);
      let lezioneHasAnagrafica = Object.assign({}, this.emptyLezioneHasAnagrafica);
      lezioneHasAnagrafica.lezione_idlezione =  this.state.lezione.idlezione;
      lezioneHasAnagrafica.lezione_atleta=anagrafica.atleta;
      lezioneHasAnagrafica.cf=anagrafica.cf;
      lezioneHasAnagrafica.cognome=anagrafica.cognome;
      lezioneHasAnagrafica.nome=anagrafica.nome;
      lezioneHasAnagrafica.atleta=anagrafica.atleta;
      lezioneHasAnagrafica.allenatore=anagrafica.allenatore;
      lezioneHasAnagrafica.dirigente=anagrafica.dirigente;
      anagrafiche.push(lezioneHasAnagrafica);
    });
    lezione.anagrafiche = anagrafiche;
    this.setState({ selectedAnagraficheCorso: e.value , lezione});
  }

  onSelectAnagraficheChange(e){
    const anagraficheCorso = this.state.anagraficheCorso;
    let lezione = Object.assign({}, this.state.lezione);
    let anagrafiche = lezione.anagrafiche.filter((el) => (anagraficheCorso.find((el2) => el.cf===el2.cf) || this.state.allenatori.find((el2) => el.cf===el2.cf && el2.atlea===0 && el2.dirigente===0) || e.value.find((el2) => el.cf===el2.cf)) );
    let added = e.value.filter((el) => !anagrafiche.find((a) => a.cf===el.cf));
    added.forEach((item, i) => {
      let anagrafica = this.state.lezioneOriginalAnagrafiche.find((el) => el.cf===item);
      anagrafica = anagrafica ? anagrafica : item;
      let lezioneHasAnagrafica = Object.assign({}, this.emptyLezioneHasAnagrafica);
      lezioneHasAnagrafica.lezione_idlezione =  this.state.lezione.idlezione;
      lezioneHasAnagrafica.lezione_atleta=anagrafica.atleta;
      lezioneHasAnagrafica.cf=anagrafica.cf;
      lezioneHasAnagrafica.cognome=anagrafica.cognome;
      lezioneHasAnagrafica.nome=anagrafica.nome;
      lezioneHasAnagrafica.atleta=anagrafica.atleta;
      lezioneHasAnagrafica.allenatore=anagrafica.allenatore;
      lezioneHasAnagrafica.dirigente=anagrafica.dirigente;
      anagrafiche.push(lezioneHasAnagrafica);
    });
    lezione.anagrafiche = anagrafiche;
    this.setState({ selectedAnagrafiche: e.value , lezione});
  }

  searchAnagrafiche(event) {
    if(event.query.trim().length>0){
      this.anagraficheService.getAnagraficheAutocomplete(20,event.query.trim()).then(result => {
        if(result.isOk && result.data){
          let filteredAnagrafiche = result.data.filter((el) => (!this.state.anagraficheCorso.find((el2) => el.cf===el2.cf) && !this.state.allenatori.find((el2) => el.cf===el2.cf && el2.atlea===0 && el2.dirigente===0)) )
          this.setState({ filteredAnagrafiche: filteredAnagrafiche });
        }
        else{
          this.setState({ filteredAnagrafiche: [] });
        }
      });
    }
    else{
      this.setState({ filteredAnagrafiche: [] });
    }
}

  corsoNomeBodyTemplate(rowData) {
      return (
          <React.Fragment>
              <span className="p-column-title">{this.props.t('label.corso_nome')}</span>
              {rowData.corso_nome}
          </React.Fragment>
      );
  }

  dataLezioneBodyTemplate(rowData) {
      return (
          <React.Fragment>
              <span className="p-column-title">{this.props.t('label.data_lezione')}</span>
              <span>{clientFormatDateTime(this.props.t('locale'),rowData.data_lezione)}</span>
          </React.Fragment>
      );
  }

  dataRecuperoBodyTemplate(rowData) {
      return (
          <React.Fragment>
              <span className="p-column-title">{this.props.t('label.data_recupero')}</span>
              <span>{clientFormatDateTime(this.props.t('locale'),rowData.data_recupero)}</span>
          </React.Fragment>
      );
  }

  daRecuperareBodyTemplate(rowData) {
    return (
        <React.Fragment>
            <span className="p-column-title">{this.props.t('label.da_recuperare')}</span>
            {rowData.da_recuperare===1 && <span className={"p-tag p-p-1 p-m-1 "+this.YesNoAllTag.yes} >{this.props.t('button.yes')}</span>}
            {rowData.da_recuperare===0 && <span className={"p-tag p-p-1 p-m-1 "+this.YesNoAllTag.no}  >{this.props.t('button.no')}</span>}
        </React.Fragment>
    );
  }

  lezioneAtletaBodyTemplate(rowData) {
    return (
        <React.Fragment>
            <span className="p-column-title">{this.props.t('label.lezione_atleta')}</span>
            {rowData.lezione_atleta===1 && <span className={"p-tag p-p-1 p-m-1 "+this.YesNoAllTag.yes} >{this.props.t('button.yes')}</span>}
            {rowData.lezione_atleta===0 && <span className={"p-tag p-p-1 p-m-1 "+this.YesNoAllTag.no} >{this.props.t('button.no')}</span>}
        </React.Fragment>
    );
  }

  lezioneProvaBodyTemplate(rowData) {
    return (
        <React.Fragment>
            <span className="p-column-title">{this.props.t('label.lezione_prova')}</span>
            {rowData.lezione_prova===1 && <span className={"p-tag p-p-1 p-m-1 "+this.YesNoAllTag.yes} >{this.props.t('button.yes')}</span>}
            {rowData.lezione_prova===0 && <span className={"p-tag p-p-1 p-m-1 "+this.YesNoAllTag.no} >{this.props.t('button.no')}</span>}
        </React.Fragment>
    );
  }


  noteBodyTemplate(rowData) {
      return (
          <React.Fragment>
              <span className="p-column-title">{this.props.t('label.note')}</span>
              <span>{rowData.note}</span>
          </React.Fragment>
      );
  }

  corsiItemTemplate(option) {
      return (
          <div className="p-multiselect-corsi-option">
              <span className="image-text">{option.nome}</span>
          </div>
      );
  }

  anagraficheItemTemplate(option) {
      return (
          <div className="p-multiselect-amagrafiche-option">
              <span className="image-text">{option.cognome} {option.nome}</span>
          </div>
      );
  }

  ruoliItemTemplate(option) {
      return (
          <div className="p-multiselect-ruoli-option">
              <span className="image-text">{option.nome}</span>
          </div>
      );
  }

  nomeBodyTemplate(rowData) {
      return (
          <React.Fragment>
              <span className="p-column-title">{this.props.t('label.nome')}</span>
              {rowData.nome}
          </React.Fragment>
      );
  }

  cognomeBodyTemplate(rowData) {
      return (
          <React.Fragment>
              <span className="p-column-title">{this.props.t('label.cognome')}</span>
              {rowData.cognome}
          </React.Fragment>
      );
  }

  ruoliBodyTemplate(rowData) {
      return (
        <React.Fragment>
            <span className="p-column-title">{this.props.t('label.ruoli')}</span>
            {rowData.atleta===1 && <span className={"p-tag p-p-1 p-m-1 "+this.ruoliTag.atleta} >{this.props.t('label.atleta')}</span>}
            {rowData.allenatore===1 && <span className={"p-tag p-p-1 p-m-1 "+this.ruoliTag.allenatore} >{this.props.t('label.allenatore')}</span>}
            {rowData.dirigente===1 && <span className={"p-tag p-p-1 p-m-1 "+this.ruoliTag.dirigente} >{this.props.t('label.dirigente')}</span>}
        </React.Fragment>
      );
  }

  columnsItemTemplate(option) {
      return (
          <div className="p-multiselect-columns-option">
              <span className="image-text">{option.nome}</span>
          </div>
      );
  }

  actionBodyTemplate(rowData) {
      return (
          <React.Fragment>
              <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-m-1" onClick={() => this.editLezione(rowData)} />
              {this.props.user && this.props.user.ruolo==="admin" &&  <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-m-1" onClick={() => this.confirmDeleteLezione(rowData)} />}
          </React.Fragment>
      );
  }

  onEditorValueChange(props, value) {
      let updatedItems = [...props.value];
      updatedItems[props.rowIndex][props.field] = value;
      let lezione = {...this.state.lezione};
      lezione.anagrafiche = updatedItems;
      this.setState({ lezione: lezione });
      if(props.field==="lezione_atleta"){
        this.refreshSelectedAllenatori(lezione.anagrafiche);
      }
  }

  inputTextEditor(props, field) {
    return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
  }

  inputTextAreaEditor(props, field) {
    return <InputTextarea value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} rows={3} cols={15} max={45} autoResize />;
  }

  yesNoNullEditor(props, field) {
    return <SelectButton optionLabel="nome" optionValue="valore" value={props.rowData[field]} options={this.YesNoAll} onChange={(e) => this.onEditorValueChange(props, e.target.value)}  />;
  }

  emptyValueValidator(props) {
      const { rowData, field } = props;
      return rowData[field].trim().length > 0;
  }

  onRowEditInit(event) {
      this.originalRows[event.index] = { ...this.state.lezione.anagrafiche[event.index] };
  }

  onRowEditCancel(event) {
      let lezione = Object.assign({}, this.state.lezione);
      lezione.anagrafiche[event.index] = this.originalRows[event.index];
      delete this.originalRows[event.index];
      this.setState({ lezione: lezione });
      this.refreshSelectedAllenatori(lezione.anagrafiche);
  }

  exportFunction(input) {
    let result = "";
    switch(input.field){
      case "da_recuperare":
        result = (input.data===1) ? this.props.t('button.yes') : this.props.t('button.no');
        break;
      default:
        result = input.data;
        break;
    }
    return result;
  }

  exportAnagraficheFunction(input) {
    let result = "";
    switch(input.field){
      case "atleta":
      case "allenatore":
      case "dirigente":
        result = (input.data===1) ? this.props.t('label.'+input.field) : "";
        break;
      default:
        result = input.data;
        break;
    }
    return result;
  }

  render() {
    if(this.inputTextRef)
      this.inputTextRef.focus();
      const leftToolbarTemplate = (
          <React.Fragment>
              <Button label={this.props.t('actions.new')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
              {false && <Button label={this.props.t('actions.delete')} icon="pi pi-trash" className="p-button-danger p-mr-2" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedLezioni || !this.state.selectedLezioni.length} />}
              <Button label={this.props.t('actions.export')} icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />
          </React.Fragment>
      );

      const rightToolbarTemplate = (
            <React.Fragment>
                <MultiSelect value={this.state.showColumns} options={this.columns} itemTemplate={this.columnsItemTemplate} onChange={(e) => this.setState({showColumns:e.value})} optionLabel="nome" optionValue="valore" placeholder={this.props.t('actions.columns')} />
            </React.Fragment>
      );

      const header = this.state.showToolbar ? (
          <div className="table-header">
              <h5 className="p-m-0">{this.props.t('title.manage_lezioni')}</h5>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText type="search" onInput={(e) => this.onFilterChange(e.target.value,"searchText","custom")} placeholder={this.props.t('actions.search')} />
              </span>
          </div>
      ) : null;

      const lezioneDialogFooter = (
          <React.Fragment>
              <Button label={this.props.t('button.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
              <Button label={this.props.t('button.save')} icon="pi pi-check" className="p-button-text" onClick={this.saveLezione} />
          </React.Fragment>
      );
      const deleteLezioneDialogFooter = (
          <React.Fragment>
              <Button label={this.props.t('button.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteLezioneDialog} />
              <Button label={this.props.t('button.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteLezione} />
          </React.Fragment>
      );
      const deleteLezioniDialogFooter = (
          <React.Fragment>
              <Button label={this.props.t('button.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteLezioniDialog} />
              <Button label={this.props.t('button.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedLezioni} />
          </React.Fragment>
      );

      const dataLezioneFilter = <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} ref={(el) => this.dlf = el} showButtonBar value={this.state.selectedDataLezione} onChange={(e) => this.onDataFilterChange(e.value,"data_lezione","contains")} placeholder={this.props.t('search.data_lezione')} yearNavigator yearRange="1920:2030" />;
      const dataRecuperoFilter = <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} ref={(el) => this.drf = el} showButtonBar value={this.state.selectedDataRecupero} onChange={(e) => this.onDataFilterChange(e.value,"data_recupero","contains")} placeholder={this.props.t('search.data_recupero')} yearNavigator yearRange="1920:2030" />;
      const corsoFilter = <MultiSelect value={this.state.selectedCorsi} options={this.state.corsi} itemTemplate={this.corsiItemTemplate} onChange={(e) => this.onFilterChange(e.value,"corso_nome","in")} optionLabel="nome" optionValue="nome" placeholder={this.props.t('search.corso_nome')} />;
      const ruoloFilter = <MultiSelect value={this.state.selectedRuoli} options={this.ruoli} itemTemplate={this.ruoliItemTemplate} onChange={(e) => this.onRuoloFilterChange(e)} optionLabel="nome" optionValue="valore" placeholder={this.props.t('search.ruoli')} />;
      const daRecuperareFilter = <SelectButton optionLabel="nome" optionValue="valore" value={this.state.selectedDaRecuperare} options={this.YesNoAll} onChange={(e) => this.onFilterChange(e.value,"da_recuperare","equals")} />;
      const atletaFilter = <SelectButton optionLabel="nome" optionValue="valore" value={this.state.selectedAtleta} options={this.YesNoAll} onChange={(e) => {this.dta.filter(e.value,"lezione_atleta","equals"); this.setState({selectedAtleta:e.value});}} />;
      const provaFilter = <SelectButton optionLabel="nome" optionValue="valore" value={this.state.selectedProva} options={this.YesNoAll} onChange={(e) => {this.dta.filter(e.value,"lezione_prova","equals"); this.setState({selectedProva:e.value});}} />;
      const paginatorLeft = <div></div>;
      const paginatorRight =
        <React.Fragment>
          <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={this.onRefresh}/>
          <Button type="button" icon="pi pi-times" className="p-button-text" onClick={this.onClear}/>
        </React.Fragment>;

      return (
          <div className={isMobile ? "datatable-laika datatable-laika-mobile" : "datatable-laika"}>
              <Toast ref={(el) => this.toast = el} />

              <div className="card">
                  {this.state.showToolbar && <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>}

                  <DataTable className="p-datatable-items"
                      ref={(el) => this.dt = el}
                      value={this.state.lezioni}
                      selection={this.state.selectedLezioni}
                      onSelectionChange={(e) => this.setState({ selectedLezioni: e.value })}
                      dataKey="idlezione" paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate={this.props.t('table.currentPageReportTemplate')}
                      paginatorLeft={paginatorLeft}
                      paginatorRight={paginatorRight}
                      paginatorPosition="top"
                      emptyMessage={this.props.t('message.lezioni_empty')}
                      header={header}
                      sortMode="multiple"
                      lazy={true}
                      rows={this.state.rows}
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      totalRecords={this.state.totalRecords}
                      first={this.state.first}
                      loading={this.state.loading}
                      onPage={this.onPage}
                      onSort={this.onSort}
                      multiSortMeta={this.state.multiSortMeta}
                      onFilter={this.onFilter}
                      filters={this.state.filters}
                      globalFilter={this.state.globalFilter}
                      csvSeparator={';'}
                      exportFunction={this.exportFunction}
                      >
                      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                      {this.state.showColumns.includes('corso_nome') && <Column field="corso_nome" header={this.props.t('label.corso_nome')} body={this.corsoNomeBodyTemplate} sortable filter filterElement={corsoFilter} />}
                      {this.state.showColumns.includes('data_lezione') && <Column field="data_lezione" header={this.props.t('label.data_lezione')} body={this.dataLezioneBodyTemplate} sortable filter filterElement={dataLezioneFilter} filterHeaderClassName="p-fluid"/>}
                      {this.state.showColumns.includes('data_recupero') && <Column field="data_recupero" header={this.props.t('label.data_recupero')} body={this.dataRecuperoBodyTemplate} sortable filter filterElement={dataRecuperoFilter} filterHeaderClassName="p-fluid"/>}
                      {this.state.showColumns.includes('da_recuperare') && <Column field="da_recuperare" header={this.props.t('label.da_recuperare')} body={this.daRecuperareBodyTemplate} sortable filter filterElement={daRecuperareFilter} filterHeaderClassName="p-fluid"/>}
                      {this.state.showColumns.includes('note') && <Column field="note" header={this.props.t('label.note')} body={this.noteBodyTemplate} filter filterPlaceholder={this.props.t('search.note')} />}
                      <Column body={this.actionBodyTemplate}></Column>
                  </DataTable>
              </div>

              <Dialog visible={this.state.lezioneDialog} style={{ width: 'auto', maxWidth: "992px"}} header={this.props.t('title.detail_lezione')} modal className="p-fluid" footer={lezioneDialogFooter} onHide={this.hideDialog}>
                  <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                      <label htmlFor="corso_nome">{this.props.t('label.corso_nome')}</label>
                      <Dropdown  value={this.state.lezione.corso_nome} disabled={!this.state.isNew} options={this.state.corsi} itemTemplate={this.corsiItemTemplate} onChange={(e) => this.onSelectChange(e,"corso_nome")} optionLabel="nome" optionValue="nome" required className={classNames({ 'p-invalid': this.state.submitted && !this.state.lezione.corso_nome })} filter={isMobile ? false : true}/>
                      {this.state.submitted && !this.state.lezione.corso_nome && <small className="p-invalid">{this.props.t('error.required.lezione.corso_nome')}</small>}
                    </div>
                    <div className="p-field p-col">
                      <label htmlFor="da_recuperare">{this.props.t('label.da_recuperare')}</label>
                      <SelectButton id="da_recuperare" optionLabel="nome" optionValue="valore" value={this.state.lezione.da_recuperare} options={this.YesNoAll} onChange={(e) => this.onInputChange(e, 'da_recuperare')}/>
                    </div>
                  </div>
                  <div className="p-formgrid p-grid">
                    <div className="p-field p-col-5">
                      <label htmlFor="data_lezione">{this.props.t('label.data_lezione')}</label>
                      <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} showButtonBar id="data_lezione" value={date(this.state.lezione.data_lezione)} onChange={(e) => this.onDateChange(e, 'data_lezione')} touchUI appendTo={document.body}  showTime hourFormat="24" yearNavigator yearRange="1920:2030" required className={classNames({ 'p-invalid': this.state.submitted && !this.state.lezione.corso_nome })}/>
                      {this.state.submitted && !this.state.lezione.data_lezione && <small className="p-invalid">{this.props.t('error.required.lezione.data_lezione')}</small>}
                    </div>
                    <div className="p-field p-col-2">
                      <label htmlFor="minuti">{this.props.t('label.minuti')}</label>
                      <InputNumber ref={(el) => this.inm = el} id="minuti" value={fixInt(this.state.lezione.minuti)} onValueChange={(e) => this.onInputNumberChange(e, 'minuti')} useGrouping={false} />
                      {this.state.submitted && !fixInt(this.state.lezione.minuti) && <small className="p-invalid">{this.props.t('error.required.lezione.minuti')}</small>}
                    </div>
                    <div className="p-field p-col-5">
                      <label htmlFor="data_recupero">{this.props.t('label.data_recupero')}</label>
                      <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} showButtonBar id="data_recupero" value={date(this.state.lezione.data_recupero)} onChange={(e) => this.onDateChange(e, 'data_recupero')} touchUI appendTo={document.body}  showTime hourFormat="24" yearNavigator yearRange="1920:2030" />
                    </div>
                  </div>
                  <div className="p-field">
                      <label htmlFor="note">{this.props.t('label.note')}</label>
                      <InputTextarea id="note" value={this.state.lezione.note || ""} onChange={(e) => this.onInputChange(e, 'note')} rows={3} cols={20} max={255}/>
                  </div>
                  <div className={isMobile ? "datatable-laika datatable-laika-mobile" : "datatable-laika"}>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="allenatori">{this.props.t('select.allenatori')}</label>
                        <MultiSelect value={this.state.selectedAllenatori} options={this.state.allenatori} itemTemplate={this.anagraficheItemTemplate} onChange={(e) => this.onSelectAllenatoriChange(e)} optionLabel="cognome" optionValue="cf" />
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="anagrafiche_corso">{this.props.t('select.anagrafiche_corso')}</label>
                        <MultiSelect value={this.state.selectedAnagraficheCorso} options={this.state.anagraficheCorso} itemTemplate={this.anagraficheItemTemplate} onChange={(e) => this.onSelectAnagraficheCorsoChange(e)} optionLabel="cognome" optionValue="cf" />
                      </div>
                    </div>
                    <div className="p-field">
                      <label htmlFor="anagrafiche">{this.props.t('select.anagrafiche')}</label>
                      <AutoComplete value={this.state.selectedAnagrafiche} suggestions={this.state.filteredAnagrafiche} itemTemplate={this.anagraficheItemTemplate} completeMethod={this.searchAnagrafiche} field="cognome" multiple onChange={(e) => this.onSelectAnagraficheChange(e)} />
                    </div>
                    <DataTable className="p-datatable-items"
                        header=<h4>{this.props.t('title.anagrafiche')} [{this.state.lezione.anagrafiche ? this.state.lezione.anagrafiche.length : 0}]</h4>
                        editMode="row"
                        dataKey="id"
                        onRowEditInit={this.onRowEditInit} onRowEditCancel={this.onRowEditCancel}
                        ref={(el) => this.dta = el}
                        value={this.state.lezione.anagrafiche}
                        selection={this.state.selectedLezioneAnagrafiche}
                        onSelectionChange={(e) => this.setState({ selectedLezioneAnagrafiche: e.value })}
                        emptyMessage={this.props.t('message.lezione_has_anagrafiche_empty')}
                        sortMode="multiple"
                        exportFunction={this.exportAnagraficheFunction}
                        >
                        <Column field="ruoli" header={this.props.t('label.ruoli')} body={this.ruoliBodyTemplate} filter filterElement={ruoloFilter} exportable={false}/>
                        <Column field="atleta" header={this.props.t('label.atleta')} className="column-hide-laika" />
                        <Column field="allenatore" header={this.props.t('label.allenatore')} className="column-hide-laika" />
                        <Column field="dirigente" header={this.props.t('label.dirigente')} className="column-hide-laika" />
                        <Column field="cognome" header={this.props.t('label.cognome')} body={this.cognomeBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.cognome')}/>
                        <Column field="nome" header={this.props.t('label.nome')} body={this.nomeBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.nome')}/>
                        <Column field="lezione_atleta" header={this.props.t('label.lezione_atleta')} editor={(props) => this.yesNoNullEditor(props, "lezione_atleta")}  body={this.lezioneAtletaBodyTemplate} sortable filter filterElement={atletaFilter} filterPlaceholder={this.props.t('search.lezione_atleta')}/>
                        <Column field="lezione_prova" header={this.props.t('label.lezione_prova')} editor={(props) => this.yesNoNullEditor(props, "lezione_prova")}  body={this.lezioneProvaBodyTemplate} sortable filter filterElement={provaFilter} filterPlaceholder={this.props.t('search.lezione_prova')}/>
                        <Column field="lezione_nota" header={this.props.t('label.lezione_nota')} sortable filter editor={(props) => this.inputTextAreaEditor(props, "lezione_nota")}  filterPlaceholder={this.props.t('search.lezione_nota')} style={{width:'20%'}}/>
                        <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                  </div>
              </Dialog>

              <Dialog visible={this.state.deleteLezioneDialog} style={{ width: '450px' }} header={this.props.t('title.confirm')} modal footer={deleteLezioneDialogFooter} onHide={this.hideDeleteLezioneDialog}>
                  <div className="confirmation-content">
                      <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                      {this.state.lezione && <span>{this.props.t('message.confirm.lezione_delete')} <br/><b>{this.state.lezione.corso_nome} {clientFormatDateTime(this.props.t('locale'),this.state.lezione.data_lezione)}</b>?</span>}
                  </div>
              </Dialog>

              <Dialog visible={this.state.deleteLezioniDialog} style={{ width: '450px' }} header={this.props.t('title.confirm')} modal footer={deleteLezioniDialogFooter} onHide={this.hideDeleteLezioniDialog}>
                  <div className="confirmation-content">
                      <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                      {this.state.lezione && <span>{this.props.t('message.confirm.lezioni_delete')} </span>}
                  </div>
              </Dialog>
          </div>
      );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Lezioni);
