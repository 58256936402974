export const searchByValore = (valueKey, myArray) => {
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].valore === valueKey) {
            return myArray[i];
        }
    }
}

export const fixInt = (value) => {
  return value ? parseInt(value) : null;
}
