import React from "react";
import {DEFINE} from "../Config/config";
import {useTranslation} from 'react-i18next';

const BodyTemplate = ({value,title,type}) => {
    const {t} = useTranslation();
    if(type==="boolean"){
      const define = new DEFINE(t);
      return(
        <React.Fragment>
            <span className="p-column-title">{t(title)}</span>
            {value===1 && <span className={"p-tag p-p-1 p-m-1 "+define.YesNoAllTag.yes} >{t('button.yes')}</span>}
            {value===0 && <span className={"p-tag p-p-1 p-m-1 "+define.YesNoAllTag.no}  >{t('button.no')}</span>}
        </React.Fragment>
      )
    }
    else if(type==="cost"){
      return(
        <React.Fragment>
            <span className="p-column-title">{t(title)}</span>
            {value ? value+" €" : ""}
        </React.Fragment>
      )
    }
    else if(type==="integer"){
      return(
        <React.Fragment>
            <span className="p-column-title">{t(title)}</span>
            {value}
        </React.Fragment>
      )
    }
    else{
      return (
          <React.Fragment>
              <span className="p-column-title">{t(title)}</span>
              {value}
          </React.Fragment>
      );
    }
  }

  export default BodyTemplate;
