/**
 * @param stringDate
 * @returns {Date}
 * @constructor
 */
export const StringToDate = (stringDate) => {
    return new Date(Date.parse(stringDate));
};

/**
 * @param stringDate
 * @returns {Date}
 * @constructor
 */
export const UnixTimestampToDate = (unixTimestamp) => {
    if(unixTimestamp){
      return new Date(unixTimestamp*1000);
    }
    return null;//new Date();
};

/**
 * @param stringDate
 * @returns {Date}
 * @constructor
 */
export const UnixTimestampToDateFixedCurrent = (unixTimestamp) => {
    if(unixTimestamp){
      return new Date(unixTimestamp*1000);
    }
    return new Date();
};

/**
 * [isToday description]
 * @param  {[type]}  someDate [description]
 * @return {Boolean}          [description]
 */
export const isToday = (someDate) => {
  const today = new Date();//new Date("2020-09-10")
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

/**
 * [isToday description]
 * @param  {[type]}  someDate [description]
 * @return {Boolean}          [description]
 */
export const isTodayUnixTimestamp = (unixTimestamp) => {
  if(unixTimestamp){
    const someDate = new Date(unixTimestamp*1000);
    return isToday(someDate);
  }
  return false;
}

/**
 * @param stringDate
 * @returns {Date}
 * @constructor
 */
export const DateToUnixTimestamp = (stringDate, clearTime) => {
    if(stringDate){
      if(clearTime){
        stringDate = new Date(stringDate.toDateString());
      }
      return parseInt((stringDate.getTime() / 1000).toFixed(0))
    }
    return null;
};



/**
 * @param date
 * @returns {string}
 * @constructor
 */
export const DateToDateString = (language,date) => {
    return date.toLocaleDateString(language, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};

/**
 * @param date
 * @returns {string}
 * @constructor
 */
export const DateToTimeString = (language,date) => {
    return date ? date.toLocaleTimeString(language, {
        hour: '2-digit',
        minute: '2-digit'
    }) : "";
};

/**
 * @param stringDate
 * @returns {string}
 * @constructor
 */
export const StringToDateTimeString = (language,stringDate) => { //ZAR api returns unixTimestamp, now we use UnixTimestampToDateTimeString
    const date = StringToDate(stringDate);
    return date ? DateToDateString(language,date) + " " + DateToTimeString(language,date) : "";
};

/**
 * @param stringDate
 * @returns {string}
 * @constructor
 */
export const UnixTimestampToDateTimeString = (unixTimestamp) => {
    //NEED in_air from api
    const date = UnixTimestampToDate(unixTimestamp);
    return date ? DateToDateString(date) + " " + DateToTimeString(date) : "";
};

/**
 * [UnixTimestampToTimeString description]
 * @param {[type]} unixTimestamp [description]
* @returns {string}
 */
export const UnixTimestampToTimeString = (unixTimestamp) => {
  const date = UnixTimestampToDate(unixTimestamp);
  return DateToTimeString(date);
};

/**
 * @param date
 * @param dayFormat
 * @returns {string}
 * @constructor
 */
export const DateToDayName = (date, dayFormat = 'short') => {
    return date ? date.toLocaleDateString([], {weekday: dayFormat}) : "";
};

/**
 * @param date
 * @param monthFormat
 * @returns {string}
 * @constructor
 */
export const DateToMonthName = (date, monthFormat = 'short') => {
    return date ? date.toLocaleDateString([], {month: monthFormat}) : "";
};

/**
 * @param date
 * @returns {Date}
 * @constructor
 */
export const FirstWeekDay = (date) => {
    let day = date.getDay();
    return AddDays(date, -day + (day === 0 ? -6 : 1));
};

/**
 * @param date
 * @param days
 * @returns {Date}
 * @constructor
 */
export const AddDays = (date, days) => {
    return new Date(date.setDate(date.getDate() + days));
};

/**
 * @param date
 * @returns {Date}
 * @constructor
 */
export const LastWeekDay = (date) => {
    date = FirstWeekDay(date);
    return AddDays(date, 6);
};

/**
 * @param seconds
 * @returns {string}
 * @constructor
 */
export const SecondsToTime = (seconds) => {
    const days = Math.floor(seconds/(60*60*24));
    if(days===1){
      return "1 giorno";
    }
    else if(days>1){
      return days+" giorni";
    }
    else{
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    }
}

export const serverFormatDate = (date) => {
    if(!date)
      return "";

    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
        month = '0' + month;
    }

    if (day < 10) {
        day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
}

const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export const serverFormatDateTime = (date) => {
    if(!date)
      return "";

    let month = addZero(date.getMonth() + 1);
    let day = addZero(date.getDate());
    let h = addZero(date.getHours());
    let m = addZero(date.getMinutes());
    let s = addZero(date.getSeconds());

    return date.getFullYear() + '-' + month + '-' + day + " " + h +":"+m+":"+s;
}

export const clientFormatDate = (language, clientDate) => {
    if(!clientDate)
      return "";

    let date = new Date(clientDate.replace(" ","T"));

    /*
    day:
    The representation of the day.
    Possible values are "numeric", "2-digit".
    weekday:
    The representation of the weekday.
    Possible values are "narrow", "short", "long".
    year:
    The representation of the year.
    Possible values are "numeric", "2-digit".
    month:
    The representation of the month.
    Possible values are "numeric", "2-digit", "narrow", "short", "long".
    hour:
    The representation of the hour.
    Possible values are "numeric", "2-digit".
    minute: The representation of the minute.
    Possible values are "numeric", "2-digit".
    second:
    The representation of the second.
    Possible values are "numeric", 2-digit".
    */

    var options = {
      //weekday: 'narrow',
      year: 'numeric', month: '2-digit', day: '2-digit'
    };

    return date.toLocaleDateString(language, options);
}

export const clientFormatDateTime = (language, clientDate) => {
    if(!clientDate)
      return "";

    let date = new Date(clientDate.replace(" ","T"));

    var options = {
      //weekday: 'narrow',
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit'
    };

    return date.toLocaleDateString(language, options).replace(",","");
}

export const date = (formatDate) => {

    return formatDate ? new Date(formatDate.replace(" ","T")) : "";
}

export const calendarLocale = (lang) => {
    let result = {};
    switch(lang){
      case "it-IT":
      default:
        result = {
            firstDayOfWeek: 1,
            dayNames: ["domenica", "lunedi", "martedi", "mercoledi", "giovadi", "venerdi", "sabato"],
            dayNamesShort: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
            dayNamesMin: ["D", "L", "Ma", "Me", "G", "V", "S"],
            monthNames: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
            monthNamesShort: ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "oct", "nov", "dic"],
            today: 'Oggi',
            clear: 'Cancella',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Sm'
        };
        break;
    }
    return result;
}
