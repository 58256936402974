import ServiceActionTypes from './service-types';

const INITIAL_STATE = {

}

const serviceReducer = (state = INITIAL_STATE, action) => {
	switch(action.type) {
		case ServiceActionTypes.SET_ANAGRAFICHE:
			return {
				...state,
				anagrafiche: action.payload
			};
		default:
			return state;
	}
}

export default serviceReducer;
