import {API_URL} from "../Config/config";
import { AuthService } from "./AuthService";
import {DateToUnixTimestamp} from "../Utils/DateHelper";

export class CorsiService {

    /**
   * Returns elements
   *
   * @param filter
   * @param paginate
   * @returns {Promise<any>}
   */
  getCorsi = (paginate,filters) => {
      let url = `${API_URL}corso/read.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      if(paginate){
        if(typeof paginate.first !== "undefined"){
          url += `&p_offset=${paginate.first}`;
        }
        if(typeof paginate.size !== "undefined"){
          url += `&p_size=${paginate.size}`;
        }
        if(typeof paginate.multiSortMeta !== "undefined"){
          url += `&p_sort=${JSON.stringify(paginate.multiSortMeta)}`;
        }
      }
      if(filters){
        url += `&filters=${JSON.stringify(filters)}`;
      }
      const requestOptions = {
          method: "GET",
          headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`}
      };
      return fetch(url, requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else{
          return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
      });
  }

  getCorso = (nome) => {
    let url = `${API_URL}corso/single_read.php?timestamp=${DateToUnixTimestamp(new Date())}`;
    if(nome){
      url += `&nome=${nome}`;
    }
    const requestOptions = {
        method: "GET",
        headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`}
    };
    return fetch(url,requestOptions).then(response => {
      if(response && response.status===200){
        return response.json();
      }
      else{
        return {isOk: false, data: [], errorMessage: "error.generic"};
      }
    }).catch(error => {
      return {isOk: false, data: [], errorMessage: "error.generic"};
    });
  }

  deleteCorso = (nome) => {
      let url = `${API_URL}corso/delete.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      const requestOptions = {
        method: "DELETE",
        headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`},
        body: JSON.stringify({nome: nome})
      };
      return fetch(url,requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else {
          return {isOk: false, errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, errorMessage: "error.generic"};
      });
  }

  createCorso = (corso) => {
      let url = `${API_URL}corso/create.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      const requestOptions = {
        method: "POST",
        headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`},
        body: JSON.stringify(corso)
      };
      return fetch(url,requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else {
          return {isOk: false, errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
      });
  }

  updateCorso = (corso) => {
      let url = `${API_URL}corso/update.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      const requestOptions = {
        method: "POST",
        headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`},
        body: JSON.stringify(corso)
      };
      return fetch(url,requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else {
          return {isOk: false, errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
      });
  }

}
