import {API_URL} from "../Config/config";
import {UnixTimestampToDate, DateToUnixTimestamp} from "../Utils/DateHelper";

export class AuthService {

  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  StoreUserToken = (token) => {
    localStorage.setItem('token', token);
  };

  GetUserToken = () => {
    return localStorage.getItem('token');
  };

  RemoveUserToken = () => {
    localStorage.removeItem('token');
    return null;
  };

  isLogin = () => {
    let token = this.GetUserToken();
    if(token){
      let payload = this.parseJwt(token);
      let expDate = UnixTimestampToDate(payload.exp);
      return expDate.getTime()>new Date().getTime();
    }
    else{
      return false;
    }
  }

  user = () => {
    let token = this.GetUserToken();
    if(token){
      let payload = this.parseJwt(token);
      let expDate = UnixTimestampToDate(payload.exp);
      if(expDate && expDate.getTime()>new Date().getTime()){
        return payload.user;
      }
      else{
        return false
      }
    }
    else{
      return false;
    }
  }

  login = (username,password) => {
    let url = `${API_URL}auth/jwt.php?timestamp=${DateToUnixTimestamp(new Date())}`;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({username: username,password:password})
    };
    return fetch(url,requestOptions).then(response => {
      if(response && response.status===200){
        return response.json().then(result => {
          if(result.isOk){
            this.StoreUserToken(result.jwt);
            return {isOk: true, data: this.parseJwt(result.jwt)};
          }
          else{
            this.RemoveUserToken();
            return {isOk: false, errorMessage: (result.errorMessage ? ("error.login."+result.errorMessage) : "error.login.generic")};
          }
        });
      }
      else {
        return {isOk: false, errorMessage: "error.login.generic"};
      }
    }).catch(error => {
      return {isOk: false, total: 0, data: [], errorMessage: "error.login.generic"};
    });
  }

  logout = () => {
    this.RemoveUserToken();
  }

}
