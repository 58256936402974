import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {store, persistor} from "./Redux/store.js";
import {PersistGate} from "redux-persist/integration/react";
import {ProgressSpinner} from 'primereact/progressspinner';

import "./i18n"; // import i18n (needs to be bundled ;))
import reportWebVitals from './reportWebVitals';

import App from './App';

ReactDOM.render(
  //<React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
          <PersistGate persistor={persistor}>
            <Suspense fallback={<ProgressSpinner/>}>
              <App />
            </Suspense>
          </PersistGate>
      </BrowserRouter>
    </Provider>
  //</React.StrictMode>
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
