import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import serviceReducer from "./Service/service-reducer";
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["search"]
};

const rootReducer = combineReducers({
  service: serviceReducer
});

export default persistReducer(persistConfig, rootReducer);
