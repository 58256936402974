import React, { Component } from 'react';
import {SITE_ROOT, DEFINE, isMobile} from "../Config/config";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AnagraficheService } from '../Services/AnagraficheService';
import { CorsiService } from '../Services/CorsiService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { date, serverFormatDate, clientFormatDate, clientFormatDateTime, calendarLocale } from '../Utils/DateHelper';
import { searchByValore, fixInt } from '../Utils/ObjectHelper';

class Anagrafiche extends Component {

    emptyAnagrafica = {
        cf: "",
        cognome: "",
        nome: "",
        nascita_citta: "",
        nascita_data: "",
        corso_nome: "",
        indirizzo: "",
        telefono: "",
        email: "",
        tessera_ilva_numero: null,
        tessera_ilva_anno: null,
        tessera_fisr_numero: null,
        tessera_fisr_stagione: "",
        certificato_scadenza: "",
        certificato_nota: "",
        certificato_status: null,
        atleta: 0,
        allenatore: 0,
        dirigente: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            userRole: props.user ? props.user.ruolo : 'none',
            corsi: null,
            anagrafiche: null,
            anagraficaDialog: false,
            deleteAnagraficaDialog: false,
            deleteAnagraficheDialog: false,
            anagrafica: this.emptyAnagrafica,
            anagraficaOldCf: null,
            selectedAnagrafiche: null,
            selectedCreazioneData: null,
            selectedNascitaData: null,
            selectedCertificatoScadenza: null,
            selectedCertificatiStatus: null,
            selectedCorsi: null,
            selectedRuoli: null,
            selectedIlvaNumero: null,
            selectedIlvaAnno: null,
            selectedFisrNumero: null,
            selectedFisrStagione: "",
            submitted: false,
            isNew: false,
            showColumns: props.showColumns ? props.showColumns : ['tessera_fisr','tessera_ilva','nascita_data'],
            showToolbar: typeof props.showToolbar === 'undefined' ? true : props.showToolbar,
            loading: false,
            first: 0,
            rows: 20,
            rowsPerPageOptions: [20, 50, 100, 200],
            totalRecords: 0,
            multiSortMeta: [],
            filters: null,
            globalFilter: "",
        };

        const define = new DEFINE(props.t)
        this.ruoliTag = define.ruoliTag;
        this.ruoli = define.ruoli;

        this.certificati_status = [
            {nome: props.t('certificato_status.ASSENTE'), valore: 'ASSENTE', tag: 'p-tag-danger p-tag-laika'},
            {nome: props.t('certificato_status.SCADUTO'), valore: 'SCADUTO', tag: 'p-tag-danger p-tag-laika'},
            {nome: props.t('certificato_status.IN_SCADENZA'), valore: 'IN SCADENZA', tag: 'p-tag-warning p-tag-laika'},
            {nome: props.t('certificato_status.OK'), valore: 'OK', tag: 'p-tag-success p-tag-laika'},
        ];

        this.columns = [
            {nome: props.t('label.cf'), valore: 'cf'},
            {nome: props.t('label.creazione_data'), valore: 'creazione_data'},
            {nome: props.t('label.tessera_ilva'), valore: 'tessera_ilva'},
            {nome: props.t('label.tessera_fisr'), valore: 'tessera_fisr'},
            {nome: props.t('label.nascita_citta'), valore: 'nascita_citta'},
            {nome: props.t('label.nascita_data'), valore: 'nascita_data'},
            {nome: props.t('label.certificato_scadenza'), valore: 'certificato_scadenza'},
            {nome: props.t('label.certificato_nota'), valore: 'certificato_nota'},
            {nome: props.t('label.covid_nota'), valore: 'covid_nota'},
            {nome: props.t('label.indirizzo'), valore: 'indirizzo'},
            {nome: props.t('label.telefono'), valore: 'telefono'},
            {nome: props.t('label.email'), valore: 'email'}
        ];

        this.calendarLocale = calendarLocale(props.t('locale'));

        this.anagraficheService = new AnagraficheService();
        this.corsiService = new CorsiService();
        this.cfBodyTemplate = this.cfBodyTemplate.bind(this);
        this.creazioneDataBodyTemplate = this.creazioneDataBodyTemplate.bind(this);
        this.nomeBodyTemplate = this.nomeBodyTemplate.bind(this);
        this.cognomeBodyTemplate = this.cognomeBodyTemplate.bind(this);
        this.nascitaCittaBodyTemplate = this.nascitaCittaBodyTemplate.bind(this);
        this.nascitaDataBodyTemplate = this.nascitaDataBodyTemplate.bind(this);
        this.lastContactWithBodyTemplate = this.lastContactWithBodyTemplate.bind(this);
        this.corsoNomeBodyTemplate = this.corsoNomeBodyTemplate.bind(this);
        this.certificatoScadenzaBodyTemplate = this.certificatoScadenzaBodyTemplate.bind(this);
        this.certificatoNotaBodyTemplate = this.certificatoNotaBodyTemplate.bind(this);
        this.covidNotaBodyTemplate = this.covidNotaBodyTemplate.bind(this);
        this.certificatoStatusBodyTemplate = this.certificatoStatusBodyTemplate.bind(this);
        this.indirizzoBodyTemplate = this.indirizzoBodyTemplate.bind(this);
        this.telefonoBodyTemplate = this.telefonoBodyTemplate.bind(this);
        this.emailBodyTemplate = this.emailBodyTemplate.bind(this);
        this.tesseraIlvaBodyTemplate = this.tesseraIlvaBodyTemplate.bind(this);
        this.tesseraFisrBodyTemplate = this.tesseraFisrBodyTemplate.bind(this);
        this.ruoliBodyTemplate = this.ruoliBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.onSelected = this.onSelected.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onDataFilterChange = this.onDataFilterChange.bind(this);
        this.onRuoloFilterChange = this.onRuoloFilterChange.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveAnagrafica = this.saveAnagrafica.bind(this);
        this.editAnagrafica = this.editAnagrafica.bind(this);
        this.confirmDeleteAnagrafica = this.confirmDeleteAnagrafica.bind(this);
        this.deleteAnagrafica = this.deleteAnagrafica.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedAnagrafiche = this.deleteSelectedAnagrafiche.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onAnagraficaRuoli = this.onAnagraficaRuoli.bind(this);
        this.anagraficaRuoli = this.anagraficaRuoli.bind(this);

        this.hideDeleteAnagraficaDialog = this.hideDeleteAnagraficaDialog.bind(this);
        this.hideDeleteAnagraficheDialog = this.hideDeleteAnagraficheDialog.bind(this);

        this.ruoliItemTemplate = this.ruoliItemTemplate.bind(this);
        this.certificatiStatusItemTemplate = this.certificatiStatusItemTemplate.bind(this);
        this.corsiItemTemplate = this.corsiItemTemplate.bind(this);

        this.onRefresh = this.onRefresh.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onSort = this.onSort.bind(this);
        this.exportFunction = this.exportFunction.bind(this);

        this.foglioModuli = this.foglioModuli.bind(this);
    }

    componentDidMount() {
        this.onRefresh();
        this.corsiService.getCorsi().then(result => {
          if(result.isOk){
            this.setState({ corsi: result.data });
          }
        });
    }

    componentDidUpdate(prevProps){
        if(prevProps.showColumns!==this.props.showColumns){
          this.setState({showColumns: this.props.showColumns ? this.props.showColumns : ['tessera_fisr','tessera_ilva','nascita_data']});
        }
        if(prevProps.showToolbar!==this.props.showToolbar){
          this.setState({showToolbar: typeof this.props.showToolbar === 'undefined' ? true : this.props.showToolbar});
        }
        if(prevProps.user!==this.props.user){
          this.setState({userRole: this.props.user ?  this.props.user.ruolo : 'none'});
        }
    }

    onLazyLoading(first,rows,filters,multiSortMeta) {
        if(this.state.loading){
          return;
        }

        this.setState({
            loading: true,
        });

        this.anagraficheService.getAnagrafiche({first:first,size:rows,multiSortMeta:multiSortMeta},filters).then(result => {
          if(result.isOk){
            this.setState({
                first: first,
                rows: rows,
                anagrafiche: result.data,
                totalRecords: result.total,
                loading: false
            });
          } else{
            this.setState({
                loading: false
            });
            this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
          }
        });
    }

    onRefresh() {
        this.onLazyLoading(this.state.first,this.state.rows,this.state.filters,this.state.multiSortMeta);
    }

    onClear() {
        this.setState({
          selectedCorsi: null,
          selectedRuoli: null,
          selectedCertificatiStatus: null,
          selectedIlvaNumero: null,
          selectedIlvaAnno: null,
          selectedFisrNumero: null,
          selectedFisrStagione: "",
          multiSortMeta: [],
          filters: {},
          globalFilter: "",
          selectedNascitaData: null,
          selectedCreazioneData: null,
          selectedCertificatoScadenza: null,
        });
        if(this.ndf) this.ndf.inputElement.value=null;
        if(this.csf) this.csf.inputElement.value=null;
        this.onLazyLoading(0,this.state.rows,null,[]);
    }

    onPage(event) {
        this.onLazyLoading(event.first,event.rows,this.state.filters,event.multiSortMeta);
    }

    onSort(event) {
      this.setState({
          multiSortMeta: event.multiSortMeta
      });
      this.onLazyLoading(this.state.first,this.state.rows,this.state.filters,event.multiSortMeta);
    }

    onFilter(event) {
      this.setState({
          filters: event.filters
      });
      this.onLazyLoading(this.state.first,this.state.rows,event.filters,this.state.multiSortMeta);
    }

    onSelected(name,value){
      switch(name){
        case "tessera_ilva_numero":
            this.setState({ selectedIlvaNumero: value });
            break;
        case "tessera_ilva_anno":
            this.setState({ selectedIlvaAnno: value });
            break;
        case "tessera_fisr_numero":
            this.setState({ selectedFisrNumero: value });
            break;
        case "tessera_fisr_stagione":
            this.setState({ selectedFisrStagione: value });
            break;
        case "certificato_status":
            this.setState({ selectedCertificatiStatus: value });
            break;
        case "creazione_data":
            this.setState({ selectedCreazioneData: value });
            break;
        case "nascita_data":
            this.setState({ selectedNascitaData: value });
            break;
        case "certificato_scadenza":
            this.setState({ selectedCertificatoScadenza: value });
            break;
        case "corso_nome":
            this.setState({ selectedCorsi: value });
            break;
        case "searchText":
            this.setState({ globalFilter: value });
            break;
        default:
          break;
      }
    }

    onFilterChange(value,name,matchMode){
      /*
      startsWith : Checks if column value starts with the filter value.
      endsWith : Checks if column value ends with the filter value.
      contains : Checks if column value contains the filter value.
      exact : Checks if string representations of column value and filter value are same.
      lt : Checks if column value is less than the filter value.
      lte : Checks if column value is less than or equals the filter value.
      gt : Checks if column value is greater than the filter value.
      gte : Checks if column value is greater than or equals the filter value.
      equals : Checks if column value equals the filter value.
      in : Checks if column value is in the collection of the filter value.
      custom: ...
       */
      this.dt.filter(value, name, matchMode);
      this.onSelected(name,value);
    }

    onDataFilterChange(value, name, matchMode) {
      if(value){
        this.dt.filter(serverFormatDate(new Date(value)), name, matchMode);
        this.onSelected(name,new Date(value));
      }
      else{
        this.dt.filter(null, name, matchMode);
        this.onSelected(name,null);
      }
    }

    onRuoloFilterChange(e) {
        let filters = this.state.filters ? this.state.filters : {};
        if(e.value.includes('atleta')){
          filters.atleta = {value:1,matchMode:'equals'};
        }
        else{
          delete filters.atleta;
        }
        if(e.value.includes('allenatore')){
          filters.allenatore = {value:1,matchMode:'equals'};
        }
        else{
          delete filters.allenatore;
        }
        if(e.value.includes('dirigente')){
          filters.dirigente = {value:1,matchMode:'equals'};
        }
        else{
          delete filters.dirigente;
        }
        this.setState({ selectedRuoli: e.value , filters: filters});
        this.onLazyLoading(this.state.first,this.state.rows,filters,this.state.multiSortMeta);
    }

    openNew() {
        this.setState({
            anagrafica: this.emptyAnagrafica,
            anagraficaOldCf: null,
            submitted: false,
            anagraficaDialog: true,
            isNew: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            anagraficaDialog: false
        });
    }

    hideDeleteAnagraficaDialog() {
        this.setState({ deleteAnagraficaDialog: false });
    }

    hideDeleteAnagraficheDialog() {
        this.setState({ deleteAnagraficheDialog: false });
    }

    saveAnagrafica() {
        if(this.state.loading){
          return;
        }

        if (this.state.anagrafica.cf && this.state.anagrafica.cf.trim() && this.state.anagrafica.cognome && this.state.anagrafica.cognome.trim() && this.state.anagrafica.nome && this.state.anagrafica.nome.trim()) {
            this.setState({
                loading: true,
            });
            let anagrafica = {...this.state.anagrafica};
            if (this.state.isNew) {
                this.anagraficheService.createAnagrafica(anagrafica).then(result => {
                  if(result.isOk){
                    this.setState({
                        loading: false,
                        anagraficaDialog: false,
                        anagrafica: this.emptyAnagrafica
                    });
                    this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.anagrafica_created_ok'), life: 5000 });
                    this.onRefresh();
                  }
                  else{
                    this.setState({
                      loading: false,
                    });
                    this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
                  }
                });
            }
            else {
                anagrafica.oldCf = this.state.anagraficaOldCf;
                this.anagraficheService.updateAnagrafica(anagrafica).then(result => {
                  if(result.isOk){
                    this.setState({
                        loading: false,
                        anagraficaDialog: false,
                        anagrafica: this.emptyAnagrafica,
                        anagraficaOldCf: null
                    });
                    this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.anagrafica_updated_ok'), life: 5000 });
                    this.onRefresh();
                  }
                  else{
                    this.setState({
                      loading: false,
                    });
                    this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
                  }
                });
            }
        }

        this.setState({
            submitted: true,
        });

    }

    editAnagrafica(anagrafica) {
        this.setState({
            anagrafica: { ...anagrafica },
            anagraficaOldCf : anagrafica.cf,
            anagraficaDialog: true,
            isNew: false
        });
    }

    confirmDeleteAnagrafica(anagrafica) {
        this.setState({
            anagrafica,
            deleteAnagraficaDialog: true
        });
    }

    deleteAnagrafica() {
        if(this.state.loading){
          return;
        }

        this.setState({
            loading: true,
        });

        this.anagraficheService.deleteAnagrafica(this.state.anagrafica.cf).then(result => {
          if(result.isOk){
            let anagrafiche = this.state.anagrafiche.filter(val => val.cf !== this.state.anagrafica.cf);
            this.setState({
                anagrafiche,
                loading: false,
                deleteAnagraficaDialog: false,
                anagrafica: this.emptyAnagrafica,
                anagraficaOldCf: null
            });
            this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.anagrafica_deleted_ok'), life: 5000 });
            this.onRefresh();
          }
          else{
            this.setState({
                loading: false,
                deleteAnagraficaDialog: false,
            });
            this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
          }
        });
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    confirmDeleteSelected() {
        this.setState({ deleteAnagraficheDialog: true });
    }

    deleteSelectedAnagrafiche() {
        let anagrafiche = this.state.anagrafiche.filter(val => !this.state.selectedAnagrafiche.includes(val));
        this.setState({
            anagrafiche,
            deleteAnagraficheDialog: false,
            selectedAnagrafiche: null
        });
        this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.anagrafiche_deleted_ok'), life: 5000 });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let anagrafica = {...this.state.anagrafica};
        anagrafica[`${name}`] = val;

        this.setState({ anagrafica });
    }

    onAnagraficaRuoli(e) {
        let anagrafica = {...this.state.anagrafica};
        anagrafica[`atleta`] = e.value ? (e.value.includes('atleta') ? 1 : 0) : 0;
        anagrafica[`allenatore`] = e.value ? (e.value.includes('allenatore') ? 1 : 0) : 0;
        anagrafica[`dirigente`] = e.value ? (e.value.includes('dirigente') ? 1 : 0) : 0;
        this.setState({ anagrafica });
    }

    anagraficaRuoli(){
      let result = [];
      if(this.state.anagrafica.atleta){
        result.push("atleta");
      }
      if(this.state.anagrafica.allenatore){
        result.push("allenatore");
      }
      if(this.state.anagrafica.dirigente){
        result.push("dirigente");
      }
      return result;
    }

    onDateChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let anagrafica = {...this.state.anagrafica};
        anagrafica[`${name}`] = val === undefined || val === null || (typeof val === 'string' && val.trim() === '') ? null : serverFormatDate(val);

        this.setState({ anagrafica });
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let anagrafica = {...this.state.anagrafica};
        anagrafica[`${name}`] = val;

        this.setState({ anagrafica });
    }

    onSelectChange(e, name) {
      const val = e.value || null;
      let anagrafica = {...this.state.anagrafica};
      anagrafica[`${name}`] = val;

      this.setState({ anagrafica });
    }

    cfBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.cf')}</span>
                {rowData.cf}
            </React.Fragment>
        );
    }

    creazioneDataBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.creazione_data')}</span>
                <span>{clientFormatDateTime(this.props.t('locale'),rowData.creazione_data)}</span>
            </React.Fragment>
        );
    }

    nomeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.nome')}</span>
                {rowData.nome}
            </React.Fragment>
        );
    }

    cognomeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.cognome')}</span>
                {rowData.cognome}
            </React.Fragment>
        );
    }

    nascitaCittaBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.nascita_citta')}</span>
                {rowData.nascita_citta}
            </React.Fragment>
        );
    }

    nascitaDataBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.nascita_data')}</span>
                <span>{clientFormatDate(this.props.t('locale'),rowData.nascita_data)}</span>
            </React.Fragment>
        );
    }

    lastContactWithBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.lastcontactwith')}</span>
                <span>{clientFormatDateTime(this.props.t('locale'),rowData.lastcontactwith)}</span>
            </React.Fragment>
        );
    }


    certificatoScadenzaBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.certificato_scadenza')}</span>
                <span>{clientFormatDate(this.props.t('locale'),rowData.certificato_scadenza)}</span>
            </React.Fragment>
        );
    }

    certificatoStatusBodyTemplate(rowData) {
        let el = searchByValore(rowData.certificato_status,this.certificati_status);
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.certificato_status')}</span>
                {rowData.atleta===1 && <span className={"p-tag "+el.tag}>{el.nome}</span>}
            </React.Fragment>
        );
    }


    certificatoNotaBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.certificato_nota')}</span>
                <span>{rowData.certificato_nota}</span>
            </React.Fragment>
        );
    }

    covidNotaBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.covid_nota')}</span>
                <span>{rowData.covid_nota}</span>
            </React.Fragment>
        );
    }



    corsoNomeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.corso_nome')}</span>
                <span>{rowData.corso_nome}</span>
            </React.Fragment>
        );
    }

    indirizzoBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.indirizzo')}</span>
                <span>{rowData.indirizzo}</span>
            </React.Fragment>
        );
    }

    telefonoBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.telefono')}</span>
                <span>{rowData.telefono}</span>
            </React.Fragment>
        );
    }

    emailBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.email')}</span>
                <span>{rowData.email}</span>
            </React.Fragment>
        );
    }

    tesseraIlvaBodyTemplate(rowData) {
        let glue = rowData.tessera_ilva_numero && rowData.tessera_ilva_anno ? " / " : "";
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.tessera_ilva')}</span>
                <span>{fixInt(rowData.tessera_ilva_numero)}</span>{glue}<span>{fixInt(rowData.tessera_ilva_anno)}</span>
            </React.Fragment>
        );
    }

    tesseraFisrBodyTemplate(rowData) {
        let glue = rowData.tessera_fisr_numero && rowData.tessera_fisr_stagione ? " - " : "";
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.tessera_fisr')}</span>
                <span>{fixInt(rowData.tessera_fisr_numero)}</span>{glue}<span>{rowData.tessera_fisr_stagione}</span>
            </React.Fragment>
        );
    }

    ruoliBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">{this.props.t('label.ruoli')}</span>
                {rowData.atleta===1 && <span className={"p-tag p-p-1 p-m-1 "+this.ruoliTag.atleta} >{this.props.t('label.atleta')}</span>}
                {rowData.allenatore===1 && <span className={"p-tag p-p-1 p-m-1 "+this.ruoliTag.allenatore} >{this.props.t('label.allenatore')}</span>}
                {rowData.dirigente===1 && <span className={"p-tag p-p-1 p-m-1 "+this.ruoliTag.dirigente} >{this.props.t('label.dirigente')}</span>}
            </React.Fragment>
        );
    }

    corsiItemTemplate(option) {
        return (
            <div className="p-multiselect-corsi-option">
                <span className="image-text">{option.nome}</span>
            </div>
        );
    }

    ruoliItemTemplate(option) {
        return (
            <div className="p-multiselect-ruoli-option">
                <span className={"p-tag "+option.tag} >{option.nome}</span>
            </div>
        );
    }

    columnsItemTemplate(option) {
        return (
            <div className="p-multiselect-columns-option">
                <span className="image-text">{option.nome}</span>
            </div>
        );
    }

    certificatiStatusItemTemplate(option) {
        return (
            <div className="p-multiselect-certificatistatus-option">
                <span className={"p-tag "+option.tag} >{option.nome}</span>
            </div>
        );
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                {this.state.userRole==="admin" && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-m-1" onClick={() => this.editAnagrafica(rowData)} />}
                {this.state.userRole==="admin" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-m-1" onClick={() => this.confirmDeleteAnagrafica(rowData)} />}
                <Button icon="pi pi-user-plus" className="p-button-rounded p-button-danger p-m-1" onClick={() =>  this.onFilterChange(rowData.cf,"lastcontactwith","custom")} />
                <Button icon="pi pi-file" className="p-button-rounded p-button-info p-m-1" onClick={() => window.open(SITE_ROOT+'fogliomoduli/'+rowData.cf)} />
            </React.Fragment>
        );
    }

    exportFunction(input) {
      let result = "";
      switch(input.field){
        case "atleta":
        case "allenatore":
        case "dirigente":
          result = (input.data===1) ? this.props.t('label.'+input.field) : "";
          break;
        default:
          result = input.data;
          break;
      }
      return result;
    }

    foglioModuli(){
      let cfs = [];
      this.state.selectedAnagrafiche.forEach((anagrafica) => {
        cfs.push(anagrafica.cf);
      });
      window.open(SITE_ROOT+'fogliomoduli/'+cfs.join(","));
    }

    render() {
        const leftToolbarTemplate = (
            <React.Fragment>
                {this.state.userRole==="admin" && <Button label={this.props.t('actions.new')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />}
                {false && <Button label={this.props.t('actions.delete')} icon="pi pi-trash" className="p-button-danger p-mr-2" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedAnagrafiche || !this.state.selectedAnagrafiche.length} />}
                <Button disabled={!this.state.selectedAnagrafiche} label={this.props.t('actions.fogliomoduli')} icon="pi pi-file" className="p-button-info p-mr-2" onClick={this.foglioModuli} />
                <Button label={this.props.t('actions.export')} icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        );

        const rightToolbarTemplate = (
                <React.Fragment>
                    <MultiSelect value={this.state.showColumns} options={this.columns} itemTemplate={this.columnsItemTemplate} onChange={(e) => this.setState({showColumns:e.value})} optionLabel="nome" optionValue="valore" placeholder={this.props.t('actions.columns')} />
                </React.Fragment>
        );

        const header = this.state.showToolbar ? (
            <div className="table-header">
                <h5 className="p-m-0">{this.props.t('title.manage_anagrafiche')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={this.state.globalFilter} onInput={(e) => this.onFilterChange(e.target.value,"searchText","custom")} placeholder={this.props.t('actions.search')} />
                </span>
            </div>
        ) : null;

        const anagraficaDialogFooter = (
            <React.Fragment>
                <Button label={this.props.t('button.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label={this.props.t('button.save')} icon="pi pi-check" className="p-button-text" onClick={this.saveAnagrafica} />
            </React.Fragment>
        );
        const deleteAnagraficaDialogFooter = (
            <React.Fragment>
                <Button label={this.props.t('button.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAnagraficaDialog} />
                <Button label={this.props.t('button.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteAnagrafica} />
            </React.Fragment>
        );
        const deleteAnagraficheDialogFooter = (
            <React.Fragment>
                <Button label={this.props.t('button.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAnagraficheDialog} />
                <Button label={this.props.t('button.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedAnagrafiche} />
            </React.Fragment>
        );

        const corsoFilter = <MultiSelect value={this.state.selectedCorsi} options={this.state.corsi} itemTemplate={this.corsiItemTemplate} onChange={(e) => this.onFilterChange(e.value,"corso_nome","in")} optionLabel="nome" optionValue="nome" placeholder={this.props.t('search.corso_nome')}  />;
        const nascitaDataFilter = <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} ref={(el) => this.ndf = el} showButtonBar value={this.state.selectedNascitaData} onChange={(e) => this.onDataFilterChange(e.value,"nascita_data","gte")}  placeholder={this.props.t('search.nascita_data')} yearNavigator yearRange="1920:2030" />;
        const creazioneDataFilter = <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} ref={(el) => this.ndf = el} showButtonBar value={this.state.selectedNascitaData} onChange={(e) => this.onDataFilterChange(e.value,"creazione_data","gte")}  placeholder={this.props.t('search.creazione_data')} yearNavigator yearRange="1920:2030" />;
        const certificatoScadenzaFilter = <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} ref={(el) => this.csf = el} showButtonBar value={this.state.selectedCertificatoScadenza} onChange={(e) => this.onDataFilterChange(e.value,"certificato_scadenza","lte")}  placeholder={this.props.t('search.certificato_scadenza')} yearNavigator yearRange="1920:2030" />;
        const ruoloFilter = <MultiSelect value={this.state.selectedRuoli} options={this.ruoli} itemTemplate={this.ruoliItemTemplate} onChange={(e) => this.onRuoloFilterChange(e)} optionLabel="nome" optionValue="valore" placeholder={this.props.t('search.ruoli')}  />;
        const certificatoStatusFilter = <MultiSelect value={this.state.selectedCertificatiStatus} options={this.certificati_status} itemTemplate={this.certificatiStatusItemTemplate} onChange={(e) => this.onFilterChange(e.value,"certificato_status","in")} optionLabel="nome" optionValue="valore" placeholder={this.props.t('search.certificato_status')}  />;
        const tesseraIlva = <React.Fragment>
          <InputNumber value={this.state.selectedIlvaNumero} onValueChange={(e) => this.onFilterChange(e.value,"tessera_ilva_numero","contains")} useGrouping={false}  placeholder={this.props.t('search.tessera_ilva_numero')}/>
          <InputNumber value={this.state.selectedIlvaAnno} onValueChange={(e) => this.onFilterChange(e.value,"tessera_ilva_anno","contains")} useGrouping={false}  placeholder={this.props.t('search.tessera_ilva_anno')}/>
        </React.Fragment>;
        const tesseraFisr = <React.Fragment>
          <InputNumber value={this.state.selectedFisrNumero} onValueChange={(e) => this.onFilterChange(e.value,"tessera_fisr_numero","contains")} useGrouping={false} placeholder={this.props.t('search.tessera_fisr_numero')}/>
          <InputText value={this.state.selectedFisrStagione} onChange={(e) => this.onFilterChange(e.target && e.target.value,"tessera_fisr_stagione","contains")} max={10} placeholder={this.props.t('search.tessera_fisr_stagione')} />
        </React.Fragment>;
        const paginatorLeft = <div></div>;
        const paginatorRight =
          <React.Fragment>
            <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={this.onRefresh}/>
            <Button type="button" icon="pi pi-times" className="p-button-text" onClick={this.onClear}/>
          </React.Fragment>;

        return (
            <div className={isMobile ? "datatable-laika datatable-laika-mobile" : "datatable-laika"}>
                <Toast ref={(el) => this.toast = el} />
                <div className="card">
                    {this.state.showToolbar && <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>}

                    <DataTable className="p-datatable-items"
                        ref={(el) => this.dt = el}
                        value={this.state.anagrafiche}
                        selection={this.state.selectedAnagrafiche}
                        onSelectionChange={(e) => this.setState({ selectedAnagrafiche: e.value })}
                        dataKey="cf" paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.props.t('table.currentPageReportTemplate')}
                        paginatorLeft={paginatorLeft}
                        paginatorRight={paginatorRight}
                        paginatorPosition="top"
                        emptyMessage={this.props.t('message.anagrafiche_empty')}
                        header={header}
                        sortMode="multiple"
                        lazy={true}
                        rows={this.state.rows}
                        rowsPerPageOptions={this.state.rowsPerPageOptions}
                        totalRecords={this.state.totalRecords}
                        first={this.state.first}
                        loading={this.state.loading}
                        onPage={this.onPage}
                        onSort={this.onSort}
                        multiSortMeta={this.state.multiSortMeta}
                        onFilter={this.onFilter}
                        filters={this.state.filters}
                        globalFilter={this.state.globalFilter}
                        csvSeparator={';'}
                        exportFunction={this.exportFunction}
                        >

                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        {this.state.showColumns.includes('cf') && <Column field="cf" header={this.props.t('label.cf')} body={this.cfBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.cf')} />}
                        {this.state.showColumns.includes('creazione_data') && <Column field="creazione_data" header={this.props.t('label.creazione_data')} body={this.creazioneDataBodyTemplate} sortable filter filterElement={creazioneDataFilter} filterHeaderClassName="p-fluid"/>}
                        <Column field="cognome" header={this.props.t('label.cognome')} body={this.cognomeBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.cognome')} />
                        <Column field="nome" header={this.props.t('label.nome')} body={this.nomeBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.nome')} />
                        {this.state.showColumns.includes('nascita_citta') && <Column field="nascita_citta" header={this.props.t('label.nascita_citta')} body={this.nascitaCittaBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.nascita_citta')} filterMatchMode="contains"/>}
                        {this.state.showColumns.includes('nascita_data') && <Column field="nascita_data" header={this.props.t('label.nascita_data')} body={this.nascitaDataBodyTemplate} sortable filter filterElement={nascitaDataFilter} filterHeaderClassName="p-fluid"/>}
                        <Column field="corso_nome" header={this.props.t('label.corso_nome')} body={this.corsoNomeBodyTemplate} sortable filter filterElement={corsoFilter} />
                        {this.state.showColumns.includes('certificato_scadenza') && <Column field="certificato_scadenza" header={this.props.t('label.certificato_scadenza')} body={this.certificatoScadenzaBodyTemplate} sortable filter  filterElement={certificatoScadenzaFilter} filterHeaderClassName="p-fluid"/>}
                        <Column field="certificato_status" header={this.props.t('label.certificato_status')} body={this.certificatoStatusBodyTemplate} sortable filter filterElement={certificatoStatusFilter}/>
                        {this.state.showColumns.includes('certificato_nota') && <Column field="certificato_nota" header={this.props.t('label.certificato_nota')} body={this.certificatoNotaBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.certificato_nota')}  filterMatchMode="contains" />}
                        {this.state.showColumns.includes('covid_nota') && <Column field="covid_nota" header={this.props.t('label.covid_nota')} body={this.covidNotaBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.covid_nota')}  filterMatchMode="contains" />}
                        {this.state.showColumns.includes('indirizzo') && <Column field="indirizzo" header={this.props.t('label.indirizzo')} body={this.indirizzoBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.indirizzo')} filterMatchMode="contains"/>}
                        {this.state.showColumns.includes('telefono') && <Column field="telefono" header={this.props.t('label.telefono')} body={this.telefonoBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.telefono')} />}
                        {this.state.showColumns.includes('email') && <Column field="email" header={this.props.t('label.email')} body={this.emailBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.email')} />}
                        {this.state.showColumns.includes('tessera_ilva') && <Column field="tessera_ilva_numero" header={this.props.t('label.tessera_ilva')} body={this.tesseraIlvaBodyTemplate} sortable filter filterElement={tesseraIlva} />}
                        {this.state.showColumns.includes('tessera_ilva') && <Column field="tessera_ilva_anno" header={this.props.t('label.tessera_ilva_anno')} className="column-hide-laika" filterHeaderClassName="column-hide-laika"/>}
                        {this.state.showColumns.includes('tessera_fisr') && <Column field="tessera_fisr_numero" header={this.props.t('label.tessera_fisr')} body={this.tesseraFisrBodyTemplate} sortable filter filterElement={tesseraFisr} />}
                        {this.state.showColumns.includes('tessera_fisr') && <Column field="tessera_fisr_stagione" header={this.props.t('label.tessera_fisr_stagione')} className="column-hide-laika" filterHeaderClassName="column-hide-laika"/>}
                        {this.state.filters && this.state.filters.lastcontactwith && <Column field="lastcontactwith" header={this.props.t('label.lastcontactwith')} body={this.lastContactWithBodyTemplate} sortable filter filterPlaceholder={this.props.t('search.lastcontactwith')} filterMatchMode="custom"/>}
                        <Column field="atleta" header={this.props.t('label.atleta')} className="column-hide-laika" filterHeaderClassName="column-hide-laika"/>
                        <Column field="allenatore" header={this.props.t('label.allenatore')} className="column-hide-laika" filterHeaderClassName="column-hide-laika" />
                        <Column field="dirigente" header={this.props.t('label.dirigente')} className="column-hide-laika" filterHeaderClassName="column-hide-laika" />
                        <Column field="ruoli" header={this.props.t('label.ruoli')} body={this.ruoliBodyTemplate} filter filterElement={ruoloFilter} exportable={false}/>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>

                <Dialog visible={this.state.anagraficaDialog} style={{ width: 'auto' }} header={this.props.t('title.detail_anagrafica')} modal className="p-fluid" footer={anagraficaDialogFooter} onHide={this.hideDialog}>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="cf">{this.props.t('label.cf')}</label>
                        <InputText id="cf" value={this.state.anagrafica.cf || ""} onChange={(e) => this.onInputChange(e, 'cf')} required className={classNames({ 'p-invalid': this.state.submitted && !this.state.anagrafica.cf })} max={20}/>
                        {this.state.submitted && !this.state.anagrafica.cf && <small className="p-invalid">{this.props.t('error.required.anagrafica.cf')}</small>}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="corso_nome">{this.props.t('label.corso_nome')}</label>
                        <Dropdown  value={this.state.anagrafica.corso_nome} options={this.state.corsi} itemTemplate={this.corsiItemTemplate} onChange={(e) => this.onSelectChange(e,"corso_nome")} optionLabel="nome" optionValue="nome" filter showClear />
                      </div>
                    </div>
                    <div className="p-field">
                        <label htmlFor="ruoli">{this.props.t('label.ruoli')}</label>
                        <MultiSelect value={this.anagraficaRuoli()} options={this.ruoli} itemTemplate={this.ruoliItemTemplate} onChange={(e) => this.onAnagraficaRuoli(e)} optionLabel="nome" optionValue="valore" />
                    </div>
                    <div className="p-field">
                        <label htmlFor="cognome">{this.props.t('label.cognome')}</label>
                        <InputText id="cognome" value={this.state.anagrafica.cognome || ""} onChange={(e) => this.onInputChange(e, 'cognome')} required className={classNames({ 'p-invalid': this.state.submitted && !this.state.anagrafica.cognome })} max={45} />
                        {this.state.submitted && !this.state.anagrafica.cognome && <small className="p-invalid">{this.props.t('error.required.anagrafica.cognome')}</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="nome">{this.props.t('label.nome')}</label>
                        <InputText id="nome" value={this.state.anagrafica.nome || ""} onChange={(e) => this.onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': this.state.submitted && !this.state.anagrafica.nome })} max={45}/>
                        {this.state.submitted && !this.state.anagrafica.nome && <small className="p-invalid">{this.props.t('error.required.anagrafica.nome')}</small>}
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                          <label htmlFor="certificato_scadenza">{this.props.t('label.certificato_scadenza')}</label>
                          <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} showButtonBar id="certificato_scadenza" value={date(this.state.anagrafica.certificato_scadenza)} onChange={(e) => this.onDateChange(e, 'certificato_scadenza')} touchUI appendTo={document.body}  yearNavigator yearRange="1920:2030" />
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="certificato_nota">{this.props.t('label.certificato_nota')}</label>
                        <InputText id="certificato_nota" value={this.state.anagrafica.certificato_nota || ""} onChange={(e) => this.onInputChange(e, 'certificato_nota')} max={45}/>
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="nascita_citta">{this.props.t('label.nascita_citta')}</label>
                        <InputText id="nascita_citta" value={this.state.anagrafica.nascita_citta || ""} onChange={(e) => this.onInputChange(e, 'nascita_citta')} max={80}/>
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="nascita_data">{this.props.t('label.nascita_data')}</label>
                        <Calendar dateFormat={this.calendarLocale.dateFormat} showIcon={true} showOnFocus={false} locale={this.calendarLocale} showButtonBar id="nascita_data" value={date(this.state.anagrafica.nascita_data)} onChange={(e) => this.onDateChange(e, 'nascita_data')} touchUI appendTo={document.body}  yearNavigator yearRange="1920:2030" />
                      </div>
                    </div>
                    <div className="p-field">
                        <label htmlFor="indirizzo">{this.props.t('label.indirizzo')}</label>
                        <InputTextarea id="indirizzo" value={this.state.anagrafica.indirizzo || ""} onChange={(e) => this.onInputChange(e, 'indirizzo')} rows={3} cols={20} max={255}/>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                          <label htmlFor="telefono">{this.props.t('label.telefono')}</label>
                          <InputText id="telefono" value={this.state.anagrafica.telefono || ""} onChange={(e) => this.onInputChange(e, 'telefono')} max={20}/>
                      </div>
                      <div className="p-field p-col">
                          <label htmlFor="email">{this.props.t('label.email')}</label>
                          <InputText id="email" value={this.state.anagrafica.email || ""} onChange={(e) => this.onInputChange(e, 'email')} max={45}/>
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                          <label htmlFor="tessera_fisr_numero">{this.props.t('label.tessera_fisr_numero')}</label>
                          <InputNumber id="tessera_fisr_numero" value={fixInt(this.state.anagrafica.tessera_fisr_numero)} onValueChange={(e) => this.onInputNumberChange(e, 'tessera_fisr_numero')} useGrouping={false} />
                      </div>
                      <div className="p-field p-col">
                          <label htmlFor="tessera_fisr_stagione">{this.props.t('label.tessera_fisr_stagione')}</label>
                          <InputText id="tessera_fisr_stagione" value={this.state.anagrafica.tessera_fisr_stagione || ""} onChange={(e) => this.onInputChange(e, 'tessera_fisr_stagione')} max={10}/>
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                          <label htmlFor="tessera_ilva_numero">{this.props.t('label.tessera_ilva_numero')}</label>
                          <InputNumber id="tessera_ilva_numero" value={fixInt(this.state.anagrafica.tessera_ilva_numero)} onValueChange={(e) => this.onInputNumberChange(e, 'tessera_ilva_numero')} useGrouping={false} />
                      </div>
                      <div className="p-field p-col">
                          <label htmlFor="tessera_ilva_anno">{this.props.t('label.tessera_ilva_anno')}</label>
                          <InputNumber id="tessera_ilva_anno" value={fixInt(this.state.anagrafica.tessera_ilva_anno)} onValueChange={(e) => this.onInputNumberChange(e, 'tessera_ilva_anno')} useGrouping={false}/>
                      </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteAnagraficaDialog} style={{ width: '450px' }} header={this.props.t('title.confirm')} modal footer={deleteAnagraficaDialogFooter} onHide={this.hideDeleteAnagraficaDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.anagrafica && <span>{this.props.t('message.confirm.anagrafica_delete')} <b>{this.state.anagrafica.name}</b>?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteAnagraficheDialog} style={{ width: '450px' }} header={this.props.t('title.confirm')} modal footer={deleteAnagraficheDialogFooter} onHide={this.hideDeleteAnagraficheDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.anagrafica && <span>{this.props.t('message.confirm.anagrafiche_delete')} </span>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Anagrafiche);
