import React, { Component } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import { AuthService } from '../Services/AuthService';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ReactComponent as Logo } from '../Assets/logo250.svg';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
          username: "",
          password: "",
          submitted: false,
          loading: false,
        };

        this.authService = new AuthService();
        this.onLogin = this.onLogin.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps){

    }

    onLogin() {
      if(this.state.loading){
        return;
      }

      if (this.state.username && this.state.username.trim() && this.state.password && this.state.password.trim()) {
          this.setState({
              loading: true,
          });
          this.authService.login(this.state.username,this.state.password).then(result => {
            this.setState({
                loading: false,
            });
            if(result.isOk){
              this.props.callback();
            } else{
              this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
            }
          });
      }

      this.setState({
          submitted: true,
      });

    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let anagrafica = {...this.state.anagrafica};
        anagrafica[`${name}`] = val;

        this.setState({ anagrafica });
    }
    render() {

        return (
          <div className="p-fluid" style={{ width: '80%', maxWidth: "450px", margin: '80px auto' }}>
            <Toast ref={(el) => this.toast = el} />
            <Logo fill="var(--primary-color)" stroke="none" style={{height: "100px", width: "100%"}}/>
            <div className="p-field">
                <label htmlFor="username">{this.props.t('label.username')}</label>
                <InputText autoComplete="off" id="username" value={this.state.username|| ""} onChange={(e) => this.setState({username:(e.target && e.target.value) || ''})} required className={classNames({ 'p-invalid': this.state.submitted && !this.state.username })} max={45} />
                {this.state.submitted && !this.state.username && <small className="p-invalid">{this.props.t('error.required.login.username')}</small>}
            </div>
            <div className="p-field">
                <label htmlFor="password">{this.props.t('label.password')}</label>
                <InputText id="password" type="password" value={this.state.password || ""} onChange={(e) => this.setState({password:(e.target && e.target.value) || ''})} required className={classNames({ 'p-invalid': this.state.submitted && !this.state.password })} max={45}/>
                {this.state.submitted && !this.state.password && <small className="p-invalid">{this.props.t('error.required.login.password')}</small>}
            </div>
            <Button label={this.props.t('button.login')} className="p-button-success" onClick={this.onLogin} />
          </div>
        )
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
