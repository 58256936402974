import './Assets/App.scss';

import React from "react";

import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";

import {Switch, Route, Redirect} from "react-router-dom";
import Login from './Components/Login';
import Anagrafiche from './Components/Anagrafiche';
import Lezioni from './Components/Lezioni';
import Corsi from './Components/Corsi';
import FoglioPresenze from './Components/FoglioPresenze';
import FoglioModuli from './Components/FoglioModuli';

import Header from './Components/Header';
import { AuthService } from './Services/AuthService';

import { SITE_ROOT } from './Config/config';

import PrimeReact from 'primereact/utils';
PrimeReact.ripple = true;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          user: null
        }
        this.authService = new AuthService();
        this.checkLogin = this.checkLogin.bind(this);
      }

      checkLogin(){
        this.setState({user: this.authService.user()});
      }

      componentDidMount() {
          this.checkLogin();
      }

      render() {
        return (
          this.state.user ? <div className="layout-wrapper">
            <div className="layout-header">
              <Header currentRoute={this.props.location.pathname} user={this.state.user} callback={this.checkLogin}/>
            </div>
            <div className="layout-content">
              <Switch>
                <Route exact path={SITE_ROOT}><Redirect to={SITE_ROOT+'anagrafiche'}/></Route>
                <Route path={SITE_ROOT+'anagrafiche'} component={() => <Anagrafiche user={this.state.user}/>} />
                <Route path={SITE_ROOT+'lezioni'} component={() => <Lezioni user={this.state.user}/>} />
                <Route path={SITE_ROOT+'corsi'} component={() => <Corsi user={this.state.user}/>} />
                <Route path={SITE_ROOT+'fogliopresenze/:corsi/:giorni'} component={() => <FoglioPresenze/>} />
                <Route path={SITE_ROOT+'fogliopresenze/:corsi'} component={() => <FoglioPresenze/>} />
                <Route path={SITE_ROOT+'fogliomoduli/:cfs'} component={() => <FoglioModuli/>} />
                <Route><Redirect to={SITE_ROOT+'anagrafiche'}/></Route>
              </Switch>
            </div>
          </div> : <Login callback={this.checkLogin}/>
        );
      }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(App);
