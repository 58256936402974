import React, { Component } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from 'react-i18next';
import { AnagraficheService } from '../Services/AnagraficheService';
import { ModuliService } from '../Services/ModuliService';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import Modulo from './Modulo';

class FoglioModuli extends Component {

  constructor(props) {
      super(props);
      this.state = {
        anagrafiche: [],
        moduli: null,
        moduloId: null
      };

      this.anagraficheService = new AnagraficheService();
      this.moduliService = new ModuliService();
  }

  componentDidMount() {
    const params = this.props.match.params;
    this.anagraficheService.getAnagraficheCfs(params.cfs.split(",")).then(result => {
      if(result.isOk){
        this.setState({ anagrafiche: result.data });
      }
    });
    this.moduliService.getModuli().then(result => {
      if(result.isOk){
        this.setState({ moduli: result.data });
      }
    });
  }

  moduliItemTemplate(option) {
      return (
          <div className="p-multiselect-moduli-option">
              <span className="image-text">{option.nome}</span>
          </div>
      );
  }

  render() {
      const leftToolbarTemplate = (
          <React.Fragment>
              <h5 className="p-m-1">{this.props.t('title.manage_fogliomoduli')}</h5>
          </React.Fragment>
      );

      const rightToolbarTemplate = (
            <React.Fragment>
                <Dropdown  value={this.state.moduloId} options={this.state.moduli} itemTemplate={this.moduliItemTemplate} onChange={(e) => this.setState({moduloId: e.value})} optionLabel="nome" optionValue="idmodulo" />
                <Button label={this.props.t('actions.print')} icon="pi pi-print" className="p-button-info p-m-1" onClick={()=>window.print()} />
            </React.Fragment>
      );

      const modulo = this.state.moduli ? this.state.moduli.find(el => el.idmodulo===this.state.moduloId) : null;
      return (
        <div className={"report-body"}>
          { modulo && modulo.header && <div className='reportHeader'>{modulo.header}</div>}
          <div className="card">
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            { this.state.anagrafiche && modulo && this.state.anagrafiche.map((anagrafica, i) => {
              return <div key={i} className="card">
                <Modulo modulo={modulo} el={anagrafica}/>
              </div>
            })}
          </div>
          { modulo && modulo.footer && <div className='reportFooter'>{modulo.footer}</div>}
        </div>
      )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps,mapDispatchToProps)
)(FoglioModuli);
