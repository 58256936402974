import React, { Component } from 'react';
import {SITE_ROOT, DEFINE, isMobile} from "../Config/config";
import {withRouter} from "react-router";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CorsiService } from '../Services/CorsiService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import BodyTemplate from './BodyTemplate';
import FilterTemplate from './FilterTemplate';

class Corsi extends Component {

  emptyCorso = {
      nome: "",
      costo_mensile: 0,
      lezione_minuti: 45,
      costo_stage_tecnico: 0,
      costo_stage_solodance: 0,
      obbligo_stage_tecnico: 0,
      obbligo_stage_solodance: 0
  };

  constructor(props) {
      super(props);

      this.state = {
          selectedCorsi: null,
          corsoDialog: false,
          deleteCorsoDialog: false,
          deleteCorsiDialog: false,
          corso: this.emptyCorso,
          corsoOldNome: null,
          submitted: false,
          isNew: false,
          showColumns: props.showColumns ? props.showColumns : ["nome","lezione_minuti","costo_mensile"],
          showToolbar: typeof props.showToolbar === 'undefined' ? true : props.showToolbar,
          loading: false,
          first: 0,
          rows: 20,
          rowsPerPageOptions: [20, 50, 100, 200],
          totalRecords: 0,
          multiSortMeta: [],
          filters: null,
          globalFilter: null,
          selectedObbligoStageTecnico: null,
          selectedObbligoStageSolodance: null
      };

      this.columns = [
          {nome: props.t('label.corso_nome'), valore: 'nome'},
          {nome: props.t('label.costo_mensile'), valore: 'costo_mensile'},
          {nome: props.t('label.lezione_minuti'), valore: 'lezione_minuti'},
          {nome: props.t('label.costo_stage_tecnico'), valore: 'costo_stage_tecnico'},
          {nome: props.t('label.costo_stage_solodance'), valore: 'costo_stage_solodance'},
          {nome: props.t('label.obbligo_stage_tecnico'), valore: 'obbligo_stage_tecnico'},
          {nome: props.t('label.obbligo_stage_solodance'), valore: 'obbligo_stage_solodance'}
      ];

      const define = new DEFINE(props.t);
      this.YesNoAllTag = define.YesNoAllTag;
      this.YesNoAll = define.YesNoAll;
      this.ruoliTag = define.ruoliTag;
      this.ruoli = define.ruoli;

      this.corsiService = new CorsiService();
      this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

      this.onSelected = this.onSelected.bind(this);
      this.onFilterChange = this.onFilterChange.bind(this);

      this.openNew = this.openNew.bind(this);
      this.hideDialog = this.hideDialog.bind(this);
      this.saveCorso = this.saveCorso.bind(this);
      this.editCorso = this.editCorso.bind(this);
      this.confirmDeleteCorso = this.confirmDeleteCorso.bind(this);
      this.deleteCorso = this.deleteCorso.bind(this);
      this.exportCSV = this.exportCSV.bind(this);
      this.foglioPresenze = this.foglioPresenze.bind(this);

      this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
      this.deleteSelectedCorsi = this.deleteSelectedCorsi.bind(this);
      this.onInputChange = this.onInputChange.bind(this);

      this.hideDeleteCorsoDialog = this.hideDeleteCorsoDialog.bind(this);
      this.hideDeleteCorsiDialog = this.hideDeleteCorsiDialog.bind(this);

      this.onRefresh = this.onRefresh.bind(this);
      this.onClear = this.onClear.bind(this);
      this.onPage = this.onPage.bind(this);
      this.onFilter = this.onFilter.bind(this);
      this.onSort = this.onSort.bind(this);

  }

  componentDidMount() {
      this.onRefresh();
  }

  componentDidUpdate(prevProps){
      if(prevProps.showColumns!==this.props.showColumns){
        this.setState({showColumns: this.props.showColumns ? this.props.showColumns : ["nome","lezione_minuti","costo_mensile"]});
      }
      if(prevProps.showToolbar!==this.props.showToolbar){
        this.setState({showToolbar: typeof this.props.showToolbar === 'undefined' ? true : this.props.showToolbar});
      }
  }

  onLazyLoading(first,rows,filters,multiSortMeta) {
      if(this.state.loading){
        return;
      }

      this.setState({
          loading: true,
      });

      this.corsiService.getCorsi({first:first,size:rows,multiSortMeta:multiSortMeta},filters).then(result => {
        if(result.isOk){
          this.setState({
              first: first,
              rows: rows,
              corsi: result.data,
              totalRecords: result.total,
              loading: false
          });
        } else{
          this.setState({
              loading: false
          });
          this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
        }
      });
  }

  onRefresh() {
      this.onLazyLoading(this.state.first,this.state.rows,this.state.filters,this.state.multiSortMeta);
  }

  onClear() {
      this.setState({
        selectedDataCorso: "",
        selectedDataRecupero: "",
        selectedDaRecuperare: null,
        selectedCorsi: null,
        multiSortMeta: [],
        filters: null,
        selectedObbligoStageTecnico: null,
        selectedObbligoStageSolodance: null,
      });
      this.onLazyLoading(0,this.state.rows,null,[]);
  }

  onPage(event) {
      this.onLazyLoading(event.first,event.rows,this.state.filters,event.multiSortMeta);
  }

  onSort(event) {
    this.setState({
        multiSortMeta: event.multiSortMeta
    });
    this.onLazyLoading(this.state.first,this.state.rows,this.state.filters,event.multiSortMeta);
  }

  onFilter(event) {
    this.setState({
        filters: event.filters
    });
    this.onLazyLoading(this.state.first,this.state.rows,event.filters,this.state.multiSortMeta);
  }

  onSelected(name,value){
    switch(name){
      case "searchText":
          this.setState({ globalFilter: value });
          break;
      default:
        break;
    }
  }

  onFilterChange(value,name,matchMode){
    /*
    startsWith : Checks if column value starts with the filter value.
    endsWith : Checks if column value ends with the filter value.
    contains : Checks if column value contains the filter value.
    exact : Checks if string representations of column value and filter value are same.
    lt : Checks if column value is less than the filter value.
    lte : Checks if column value is less than or equals the filter value.
    gt : Checks if column value is greater than the filter value.
    gte : Checks if column value is greater than or equals the filter value.
    equals : Checks if column value equals the filter value.
    in : Checks if column value is in the collection of the filter value.
    custom: ...
     */
    this.dt.filter(value, name, matchMode);
    this.onSelected(name,value);
  }

  openNew() {
      this.setState({
          corso: this.emptyCorso,
          corsoOldNome : null,
          submitted: false,
          corsoDialog: true,
          isNew: true
      });
  }

  hideDialog() {
      this.setState({
          submitted: false,
          corsoDialog: false
      });
  }

  hideDeleteCorsoDialog() {
      this.setState({ deleteCorsoDialog: false });
  }

  hideDeleteCorsiDialog() {
      this.setState({ deleteCorsiDialog: false });
  }

  saveCorso() {
      if(this.state.loading){
        return;
      }
      if (this.state.corso.nome && this.state.corso.nome.trim() && this.state.corso.costo_mensile>0 && this.state.corso.lezione_minuti>0) {
          this.setState({
              loading: true,
          });
          let corso = {...this.state.corso};
          if (this.state.isNew) {
              this.corsiService.createCorso(corso).then(result => {
                if(result.isOk){
                  this.setState({
                      loading: false,
                      corsoDialog: false,
                      corso: this.emptyCorso
                  });
                  this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.corso_created_ok'), life: 5000 });
                  this.onRefresh();
                }
                else{
                  this.setState({
                    loading: false,
                  });
                  this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
                }
              });
          }
          else {
              corso.oldNome = this.state.corsoOldNome;
              this.corsiService.updateCorso(corso).then(result => {
                if(result.isOk){
                  this.setState({
                      loading: false,
                      corsoDialog: false,
                      corso: this.emptyCorso
                  });
                  this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.corso_updated_ok'), life: 5000 });
                  this.onRefresh();
                }
                else{
                  this.setState({
                    loading: false,
                  });
                  this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
                }
              });
          }
      }

      this.setState({
          submitted: true,
      });

  }

  editCorso(corso) {
      if(this.state.loading){
        return;
      }

      this.setState({
          loading: true,
          selectedRuoli: [],
          selectedAllenatori: [],
          selectedAnagraficheCorso: [],
          selectedAnagrafiche: [],
          filteredAnagrafiche: [],
          corso: this.emptyCorso,
          corsoOldNome : null
      });

      this.corsiService.getCorso(corso ? corso.nome : null).then(result => {
        if(result.isOk){
          if(result.data){
            this.setState({
                corso: result.data,
                corsoOldNome: result.data.nome,
                corsoDialog: true,
                isNew: false,
                loading: false
            });
          }
          else{
            this.setState({
                loading: false
            });
            this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t('error.not_found'), life: 5000 });
          }
        } else{
          this.setState({
              loading: false
          });
          this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
        }
      });

  }

  confirmDeleteCorso(corso) {
      this.setState({
          corso,
          deleteCorsoDialog: true
      });
  }

  deleteCorso() {
      if(this.state.loading){
        return;
      }

      this.setState({
          loading: true,
      });

      this.corsiService.deleteCorso(this.state.corso.nome).then(result => {
        if(result.isOk){
          let corsi = this.state.corsi.filter(val => val.nome !== this.state.corso.nome);
          this.setState({
              corsi,
              loading: false,
              deleteCorsoDialog: false,
              corso: this.emptyCorso,
              corsoOldNome: null
          });
          this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.corso_deleted_ok'), life: 5000 });
          this.onRefresh();
        }
        else{
          this.setState({
              loading: false,
              deleteCorsoDialog: false,
          });
          this.toast.show({ severity: 'error', summary: this.props.t('message.summary.error'), detail: this.props.t(result.errorMessage), life: 5000 });
        }
      });
  }

  exportCSV() {
      this.dt.exportCSV();
  }

  foglioPresenze(){
    let corsi = [];
    this.state.selectedCorsi.forEach((corso) => {
      corsi.push(corso.nome);
    });
    window.open(SITE_ROOT+'fogliopresenze/'+corsi.join(","));
  }

  confirmDeleteSelected() {
      this.setState({ deleteCorsiDialog: true });
  }

  deleteSelectedCorsi() {
      let corsi = this.state.corsi.filter(val => !this.state.selectedCorsi.includes(val));
      this.setState({
          corsi,
          deleteCorsiDialog: false,
          selectedCorsi: null
      });
      this.toast.show({ severity: 'success', summary: this.props.t('message.summary.successful'), detail: this.props.t('message.detail.corsi_deleted_ok'), life: 5000 });
  }

  onInputChange(e, name) {
      let val = (e.target && e.target.value);
      val = val || val===0 ? val : '';
      let corso = {...this.state.corso};
      corso[`${name}`] = val;

      this.setState({ corso });
  }

  actionBodyTemplate(rowData) {
      return (
          <React.Fragment>
              {this.props.user && this.props.user.ruolo==="admin" &&  <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-m-1" onClick={() => this.editCorso(rowData)} />}
              {this.props.user && this.props.user.ruolo==="admin" &&  <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-m-1" onClick={() => this.confirmDeleteCorso(rowData)} />}
              <Button icon="pi pi-briefcase" className="p-button-rounded p-button-info p-m-1" onClick={() => window.open(SITE_ROOT+'fogliopresenze/'+rowData.nome)} />
          </React.Fragment>
      );
  }

  render() {
    if(this.inputTextRef)
      this.inputTextRef.focus();
      const leftToolbarTemplate = (
          <React.Fragment>
              <Button label={this.props.t('actions.new')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
              {false && <Button label={this.props.t('actions.delete')} icon="pi pi-trash" className="p-button-danger p-mr-2" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedCorsi || !this.state.selectedCorsi.length} />}
              <Button disabled={!this.state.selectedCorsi} label={this.props.t('actions.fogliopresenze')} icon="pi pi-briefcase" className="p-button-info p-mr-2" onClick={this.foglioPresenze} />
              <Button label={this.props.t('actions.export')} icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />
          </React.Fragment>
      );

      const rightToolbarTemplate = (
            <React.Fragment>
                <MultiSelect value={this.state.showColumns} options={this.columns} itemTemplate={this.columnsItemTemplate} onChange={(e) => this.setState({showColumns:e.value})} optionLabel="nome" optionValue="valore" placeholder={this.props.t('actions.columns')} />
            </React.Fragment>
      );

      const header = this.state.showToolbar ? (
          <div className="table-header">
              <h5 className="p-m-0">{this.props.t('title.manage_corsi')}</h5>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText type="search" onInput={(e) => this.onFilterChange(e.target.value,"searchText","custom")} placeholder={this.props.t('actions.search')} />
              </span>
          </div>
      ) : null;

      const corsoDialogFooter = (
          <React.Fragment>
              <Button label={this.props.t('button.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
              <Button label={this.props.t('button.save')} icon="pi pi-check" className="p-button-text" onClick={this.saveCorso} />
          </React.Fragment>
      );
      const deleteCorsoDialogFooter = (
          <React.Fragment>
              <Button label={this.props.t('button.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCorsoDialog} />
              <Button label={this.props.t('button.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteCorso} />
          </React.Fragment>
      );
      const deleteCorsiDialogFooter = (
          <React.Fragment>
              <Button label={this.props.t('button.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCorsiDialog} />
              <Button label={this.props.t('button.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedCorsi} />
          </React.Fragment>
      );

      const paginatorLeft = <div></div>;
      const paginatorRight =
        <React.Fragment>
          <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={this.onRefresh}/>
          <Button type="button" icon="pi pi-times" className="p-button-text" onClick={this.onClear}/>
        </React.Fragment>;

      return (
          <div className={isMobile ? "datatable-laika datatable-laika-mobile" : "datatable-laika"}>
              <Toast ref={(el) => this.toast = el} />

              <div className="card">
                  {this.state.showToolbar && <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>}

                  <DataTable className="p-datatable-items"
                      ref={(el) => this.dt = el}
                      value={this.state.corsi}
                      selection={this.state.selectedCorsi}
                      onSelectionChange={(e) => this.setState({ selectedCorsi: e.value })}
                      dataKey="nome" paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate={this.props.t('table.currentPageReportTemplate')}
                      paginatorLeft={paginatorLeft}
                      paginatorRight={paginatorRight}
                      paginatorPosition="top"
                      emptyMessage={this.props.t('message.corsi_empty')}
                      header={header}
                      sortMode="multiple"
                      lazy={true}
                      rows={this.state.rows}
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      totalRecords={this.state.totalRecords}
                      first={this.state.first}
                      loading={this.state.loading}
                      onPage={this.onPage}
                      onSort={this.onSort}
                      multiSortMeta={this.state.multiSortMeta}
                      onFilter={this.onFilter}
                      filters={this.state.filters}
                      globalFilter={this.state.globalFilter}
                      csvSeparator={';'}
                      exportFunction={this.exportFunction}
                      >
                      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                      {this.state.showColumns.includes('nome') && <Column field="nome" header={this.props.t('label.nome')} body={(rowData) => <BodyTemplate value={rowData.nome} title="label.nome"/>} sortable filter filterPlaceholder={this.props.t('search.nome')}  />}
                      {this.state.showColumns.includes('costo_mensile') && <Column field="costo_mensile" header={this.props.t('label.costo_mensile')} body={(rowData) => <BodyTemplate value={rowData.costo_mensile} title="label.costo_mensile"  type="cost"/>} sortable filter filterPlaceholder={this.props.t('search.costo_mensile')} />}
                      {this.state.showColumns.includes('lezione_minuti') && <Column field="lezione_minuti" header={this.props.t('label.lezione_minuti')} body={(rowData) => <BodyTemplate value={rowData.lezione_minuti} title="label.lezione_minuti" type="integer" />} sortable filter filterPlaceholder={this.props.t('search.lezione_minuti')} />}
                      {this.state.showColumns.includes('costo_stage_tecnico') && <Column field="costo_stage_tecnico" header={this.props.t('label.costo_stage_tecnico')} body={(rowData) => <BodyTemplate value={rowData.costo_stage_tecnico} title="label.costo_stage_tecnico" type="cost"/>} sortable filter filterPlaceholder={this.props.t('search.costo_stage_tecnico')} />}
                      {this.state.showColumns.includes('costo_stage_solodance') && <Column field="costo_stage_solodance" header={this.props.t('label.costo_stage_solodance')} body={(rowData) => <BodyTemplate value={rowData.costo_stage_solodance} title="label.costo_stage_solodance" type="cost"/>} sortable filter filterPlaceholder={this.props.t('search.costo_stage_solodance')} />}
                      {this.state.showColumns.includes('obbligo_stage_tecnico') && <Column field="obbligo_stage_tecnico" header={this.props.t('label.obbligo_stage_tecnico')}
                        body={(rowData) => <BodyTemplate value={rowData.obbligo_stage_tecnico} title="label.obbligo_stage_tecnico" type="boolean"/>}
                        sortable filter
                        filterHeaderClassName="p-fluid"
                        filterElement={<FilterTemplate value={this.state.selectedObbligoStageTecnico} onChange={(e) => {this.dt.filter(e.value,"obbligo_stage_tecnico","equals"); this.setState({selectedObbligoStageTecnico:e.value});}} type="boolean"/>}
                        filterPlaceholder={this.props.t('search.obbligo_stage_tecnico')}/>}
                      {this.state.showColumns.includes('obbligo_stage_solodance') && <Column field="obbligo_stage_solodance" header={this.props.t('label.obbligo_stage_solodance')}
                        body={(rowData) => <BodyTemplate value={rowData.obbligo_stage_solodance} title="label.obbligo_stage_solodance" type="boolean"/>}
                        sortable filter
                        filterHeaderClassName="p-fluid"
                        filterElement={<FilterTemplate value={this.state.selectedObbligoStageSolodance} onChange={(e) => {this.dt.filter(e.value,"obbligo_stage_solodance","equals"); this.setState({selectedObbligoStageSolodance:e.value});}} type="boolean"/>}
                        filterPlaceholder={this.props.t('search.obbligo_stage_solodance')}/>}
                      <Column body={this.actionBodyTemplate}></Column>
                  </DataTable>
              </div>

              <Dialog visible={this.state.corsoDialog} style={{ width: 'auto', maxWidth: "992px"}} header={this.props.t('title.detail_corso')} modal className="p-fluid" footer={corsoDialogFooter} onHide={this.hideDialog}>
                  <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                      <label htmlFor="nome">{this.props.t('label.corso_nome')}</label>
                      <InputText id="nome" value={this.state.corso.nome || ""} onChange={(e) => this.onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': this.state.submitted && !this.state.corso.nome })} max={20}/>
                      {this.state.submitted && !this.state.corso.nome && <small className="p-invalid">{this.props.t('error.required.corso.nome')}</small>}
                    </div>
                  </div>
              </Dialog>

              <Dialog visible={this.state.deleteCorsoDialog} style={{ width: '450px' }} header={this.props.t('title.confirm')} modal footer={deleteCorsoDialogFooter} onHide={this.hideDeleteCorsoDialog}>
                  <div className="confirmation-content">
                      <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                      {this.state.corso && <span>{this.props.t('message.confirm.corso_delete')} <br/><b>{this.state.corso.nome}</b>?</span>}
                  </div>
              </Dialog>

              <Dialog visible={this.state.deleteCorsiDialog} style={{ width: '450px' }} header={this.props.t('title.confirm')} modal footer={deleteCorsiDialogFooter} onHide={this.hideDeleteCorsiDialog}>
                  <div className="confirmation-content">
                      <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                      {this.state.corso && <span>{this.props.t('message.confirm.corsi_delete')} </span>}
                  </div>
              </Dialog>
          </div>
      );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Corsi);
