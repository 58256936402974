import React, { Component } from 'react';
import { ReactComponent as Logo } from '../Assets/logo250.svg';
import { AuthService } from '../Services/AuthService';

import {withRouter} from "react-router";
import {compose} from "redux";
import {connect} from "react-redux";

import {withTranslation} from 'react-i18next';

import { SITE_ROOT } from '../Config/config';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state ={

        }

        this.authService = new AuthService();
    }

    render() {
        return (
          <React.Fragment>
          <div className="header-logo">
          <a className="logo" aria-label="Laika logo" href={SITE_ROOT}><Logo fill="var(--primary-color)" stroke="none" alt="logo"/></a>
          {this.props.user && <h4 style={{color: "var(--primary-color-text)", background: "var(--primary-color)"}} className="p-m-2 p-p-1">{this.props.user.nome}</h4>}
          </div>
          {this.props.user && <ul className="header-menu p-unselectable-text" role="menubar">
            <li role="none"><a role="menuitem" className={this.props.currentRoute===(SITE_ROOT+'anagrafiche') ? "p-selected header-root-link" :"header-root-link"} aria-haspopup="false" href={SITE_ROOT+'anagrafiche'}>{this.props.t('menu.anagrafiche')}</a></li>
            <li role="none"><a role="menuitem" className={this.props.currentRoute===(SITE_ROOT+'lezioni') ? "p-selected header-root-link" :"header-root-link"}  aria-haspopup="false" href={SITE_ROOT+'lezioni'}>{this.props.t('menu.lezioni')}</a></li>
            <li role="none"><a role="menuitem" className={this.props.currentRoute===(SITE_ROOT+'corsi') ? "p-selected header-root-link" :"header-root-link"}  aria-haspopup="false" href={SITE_ROOT+'corsi'}>{this.props.t('menu.corsi')}</a></li>
            <li role="none"><a role="menuitem" className="header-root-link" aria-haspopup="false" href={SITE_ROOT} onClick={(e) => {this.authService.logout(); this.props.callback(); }}>{this.props.t('menu.logout')}</a></li>
          </ul>}
          </React.Fragment>
        );
    }
  };

const mapStateToProps = (state) => ({

});


const mapDispatchToProps = dispatch => ({

});

export default compose(
    withRouter,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Header);
