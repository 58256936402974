import React, { Component } from 'react';
import {isMobile} from "../Config/config";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from 'react-i18next';
import { AnagraficheService } from '../Services/AnagraficheService';
import { clientFormatDate } from '../Utils/DateHelper'
import { fixInt } from '../Utils/ObjectHelper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';

class FoglioPresenze extends Component {

  constructor(props) {
      super(props);
      this.state = {
        anagrafiche: [],
        giorni: (props.match.params.giorni ? parseInt(props.match.params.giorni) : 5)
      };

      this.anagraficheService = new AnagraficheService();
      this.tesseraIlvaBodyTemplate = this.tesseraIlvaBodyTemplate.bind(this);
  }

  componentDidMount() {
    const params = this.props.match.params;
    this.anagraficheService.getAnagraficheCorsi(params.corsi.split(",")).then(result => {
      if(result.isOk){
        this.setState({ anagrafiche: result.data });
      }
    });
  }

  tesseraIlvaBodyTemplate(anagrafica) {
    let glue = anagrafica.tessera_ilva_numero && anagrafica.tessera_ilva_anno ? " / " : "";
    return <React.Fragment>{fixInt(anagrafica.tessera_ilva_numero)}{glue}{fixInt(anagrafica.tessera_ilva_anno)}</React.Fragment>
  }

  render() {
      const numDays = Array.from(Array(this.state.giorni).keys());

      const header =(
          <div className="table-header">
              <h5 className="p-m-1">{this.props.t('title.manage_fogliopresenze')}</h5>
              <span className="p-input-icon-left">
                <InputNumber className="p-m-1" inputClassName="p-w-60" value={this.state.giorni} onValueChange={(e) => this.setState({giorni: e.value || 0})} useGrouping={false} placeholder={this.props.t('actions.days')} min={2} max={7} showButtons />
                <Button label={this.props.t('actions.print')} icon="pi pi-print" className="p-button-info p-m-1" onClick={()=>window.print()} />
              </span>
          </div>
      );
      return (
        <div className={isMobile ? "report-body datatable-laika datatable-laika-mobile" : "report-body datatable-laika"}>
          <div className="card">
            { true ?
              <DataTable className="p-datatable-items"
                cla
                value={this.state.anagrafiche}
                header={header}
                var="anagrafica"
                dataKey="cf" >
                <Column field="corso_nome" header={this.props.t('label.corso_nome')} />
                <Column field="tessera_ilva" header={this.props.t('label.tessera_ilva')} body={(rowData) => this.tesseraIlvaBodyTemplate(rowData)} />
                <Column field="cognome" header={this.props.t('label.cognome')} />
                <Column field="nome" header={this.props.t('label.nome')} />
                <Column field="certificato_scadenza" header={this.props.t('label.certificato_scadenza')} body={(rowData)=> clientFormatDate(this.props.t('locale'),rowData.certificato_scadenza)}/>
                <Column field="certificato_status" header={this.props.t('label.certificato_status')} />
                <Column field="certificato_nota" header={this.props.t('label.certificato_nota')} />
                {numDays.map((index) => (
                  <Column key={index} header={""} className="only-print" filterHeaderClassName="only-print"/>
                 ))}
              </DataTable>
              :
              <table>
                <thead>
                  <tr>
                    <th>{this.props.t('label.corso_nome')}</th>
                    <th>{this.props.t('label.tessera_ilva')}</th>
                    <th>{this.props.t('label.cognome')}</th>
                    <th>{this.props.t('label.nome')}</th>
                    <th>{this.props.t('label.certificato_scadenza')}</th>
                    <th>{this.props.t('label.certificato_status')}</th>
                    <th>{this.props.t('label.certificato_nota')}</th>
                    {numDays.map((index) => (
                      <th key={index}></th>
                     ))}
                  </tr>
                </thead>
                <tbody>
                {this.state.anagrafiche && this.state.anagrafiche.map((anagrafica, i) => {
                  return <tr key={i}>
                    <td valign='middle'>{anagrafica.corso_nome}</td>
                    <td valign='middle'>{this.tesseraIlvaBodyTemplate(anagrafica)}</td>
                    <td valign='middle'>{anagrafica.cognome}</td>
                    <td valign='middle'>{anagrafica.nome}</td>
                    <td valign='middle'>{clientFormatDate(this.props.t('locale'),anagrafica.certificato_scadenza)}</td>
                    <td valign='middle'>{anagrafica.certificato_status}</td>
                    <td valign='middle'>{anagrafica.certificato_nota}</td>
                    {numDays.map((index) => (
                      <td key={index} valign='middle'></td>
                     ))}
                  </tr>
                 })}
                 </tbody>
              </table>
            }
          </div>
        </div>
      )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps,mapDispatchToProps)
)(FoglioPresenze);
