import React from "react";
import { DateToDateString, clientFormatDate } from '../Utils/DateHelper';
import {useTranslation} from 'react-i18next';

const Modulo = ({modulo,el}) => {
  const {t} = useTranslation();
  const dates = ["data_lezione","data_recupero","covid_datafirma","nascita_data","certificato_scadenza"];
    if(modulo && el){
      let parsedTemplate = modulo.template;
      Object.keys(el).forEach(element => {
        if(dates.includes(element)){
          parsedTemplate = parsedTemplate.replaceAll("%"+element+"%",clientFormatDate(t('locale'),el[element]));
        }
        else{
          parsedTemplate = parsedTemplate.replaceAll("%"+element+"%",el[element]);
        }
      });
      parsedTemplate = parsedTemplate.replaceAll("%oggi%",DateToDateString(t('locale'),new Date()));
      return modulo.background ?
            <div className="modulo-background">
                <img src={"data:image/png;base64,"+modulo.background} alt={modulo.nome}/>
                <div dangerouslySetInnerHTML={{ __html: parsedTemplate}}></div>
            </div>
            :
            <div className={'modulo'+(modulo.header ? ' modulo-withHeader' : '')+(modulo.footer ? ' modulo-withFooter' : '')}>
                <div dangerouslySetInnerHTML={{ __html: parsedTemplate}}></div>
                <div className="pagebreak"></div>
            </div>
    }
    return null;
  }

  export default Modulo;
