import {API_URL} from "../Config/config";
import { AuthService } from "./AuthService";
import {DateToUnixTimestamp} from "../Utils/DateHelper";

export class AnagraficheService {

    /**
   * Returns elements
   *
   * @param filter
   * @param paginate
   * @returns {Promise<any>}
   */
  getAnagrafiche = (paginate,filters) => {
      let url = `${API_URL}anagrafica/read.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      if(paginate){
        if(typeof paginate.first !== "undefined"){
          url += `&p_offset=${paginate.first}`;
        }
        if(typeof paginate.size !== "undefined"){
          url += `&p_size=${paginate.size}`;
        }
        if(typeof paginate.multiSortMeta !== "undefined"){
          url += `&p_sort=${JSON.stringify(paginate.multiSortMeta)}`;
        }
      }
      if(filters){
        url += `&filters=${JSON.stringify(filters)}`;
      }
      const requestOptions = {
          method: "GET",
          headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`}
      };
      return fetch(url, requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else{
          return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
      });
  }

      /**
     * Returns elements
     *
     * @param filter
     * @param paginate
     * @returns {Promise<any>}
     */
    getAnagraficheAutocomplete = (num,searchText) => {
        let url = `${API_URL}anagrafica/read.php?timestamp=${DateToUnixTimestamp(new Date())}`;
        if(num){
          url += `&p_offset=0`;
          url += `&p_size=${num}`;
        }
        if(searchText){
          let filters = {searchText : {value:searchText,matchMode:'custom'}};
          url += `&filters=${JSON.stringify(filters)}`;
        }
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`}
        };
        return fetch(url,requestOptions).then(response => {
          if(response && response.status===200){
            return response.json();
          }
          else{
            return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
          }
        }).catch(error => {
          return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
        });
    }

      /**
     * Returns elements
     *
     * @param filter
     * @param paginate
     * @returns {Promise<any>}
     */
    getAllenatori = () => {
        let filters = {allenatore : {value:1,matchMode:'equals'}};
        return this.getAnagrafiche(null,filters);
    }

    /**
   * Returns elements
   *
   * @param filter
   * @param paginate
   * @returns {Promise<any>}
   */
  getAnagraficheCorso = (corso_nome) => {
      let filters = {corso_nome : {value:corso_nome,matchMode:'equals'}};
      return this.getAnagrafiche(null,filters);
  }

  /**
  * Returns elements
  *
  * @param filter
  * @param paginate
  * @returns {Promise<any>}
  */
  getAnagraficheCorsi = (corsi) => {
      let paginate = {multiSortMeta : [{ field:'corso_nome', order: 1}, { field:'cognome', order: 1}, { field:'nome', order: 1}]};
      let filters = {corso_nome : {value: corsi, matchMode:'in'}, atleta : {value: 1, matchMode:'equals'}};
      return this.getAnagrafiche(paginate,filters);
  }

  /**
 * Returns elements
 *
 * @param filter
 * @param paginate
 * @returns {Promise<any>}
 */
  getAnagraficheCfs = (cfs) => {
      let filters = {cf : {value:cfs,matchMode:'in'}};
      return this.getAnagrafiche(null,filters);
  }

  deleteAnagrafica = (cf) => {
      let url = `${API_URL}anagrafica/delete.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      const requestOptions = {
        method: "DELETE",
        headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`},
        body: JSON.stringify({cf: cf})
      };
      return fetch(url,requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else {
          return {isOk: false, errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, errorMessage: "error.generic"};
      });
  }

  createAnagrafica = (anagrafica) => {
      let url = `${API_URL}anagrafica/create.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      const requestOptions = {
        method: "POST",
        headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`},
        body: JSON.stringify(anagrafica)
      };
      return fetch(url,requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else {
          return {isOk: false, errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
      });
  }

  updateAnagrafica = (anagrafica) => {
      let url = `${API_URL}anagrafica/update.php?timestamp=${DateToUnixTimestamp(new Date())}`;
      const requestOptions = {
        method: "POST",
        headers: { Authorization: `Bearer ${new AuthService().GetUserToken()}`},
        body: JSON.stringify(anagrafica)
      };
      return fetch(url,requestOptions).then(response => {
        if(response && response.status===200){
          return response.json();
        }
        else {
          return {isOk: false, errorMessage: "error.generic"};
        }
      }).catch(error => {
        return {isOk: false, total: 0, data: [], errorMessage: "error.generic"};
      });
  }



}
