import React from "react";
import {DEFINE} from "../Config/config";
import {useTranslation} from 'react-i18next';
import { SelectButton } from 'primereact/selectbutton';

const FiltetTemplate = ({value,onChange,type}) => {
    const {t} = useTranslation();
    if(type==="boolean"){
      const define = new DEFINE(t);
      return(
        <SelectButton optionLabel="nome" optionValue="valore" value={value} options={define.YesNoAll} onChange={onChange} />
      )
    }
    else{
      return (
        ""
      );
    }
  }

  export default FiltetTemplate;
